const firebaseConfig = {
  apiKey: "AIzaSyCpk7HNQaRiukXHfnWCZyzk2GxYBNukcD4",
  authDomain: "getsmilethai.firebaseapp.com",
  projectId: "getsmilethai",
  storageBucket: "getsmilethai.appspot.com",
  messagingSenderId: "509244925716",
  appId: "1:509244925716:web:10bf44568ff1da635dc401",
  measurementId: "G-CG1R8RX64F"
};
export default firebaseConfig 
