
import React, { lazy, Suspense } from 'react'

export const route = [
{ path: '/productList', Component: lazy(() => import('app/amaraffe/pages/product/list'))},
{ path: '/product/:id', Component: lazy(() => import('app/amaraffe/pages/product/edit'))},

{ path: '/productGroupList', Component: lazy(() => import('app/amaraffe/pages/productGroup/list'))},
{ path: '/productGroup/:id', Component: lazy(() => import('app/amaraffe/pages/productGroup/edit'))},

]

export default route