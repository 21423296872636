import React, { lazy, Suspense } from 'react'
import { route as menuDefaultRoute } from './menuDefault'
import { route as menuMainRoute } from './menuMain'

export const menuFront = [
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', icon: 'fe fe-phone-call', disabled: true, },
    // { url: '/web/pricing', key: 'pricing', title: 'บริการและราคา', },

    // { url: '/web/customerLeads', key: 'customerLeads', title: 'Customer Leads', },
    // { url: '/web/customer', key: 'customer', title: 'Customer', },
    // { url: '/web/supplier', key: 'supplier', title: 'Supplier', },
    // { url: '/web/employee', key: 'employee', title: 'Employee', },

    // { url: 'https://www.facebook.com/upbrand.asia', key: 'facebook', title: 'facebook', icon: 'fe fe-facebook', },
    // { url: 'tel:+66855096612', key: 'tel', title: 'โทรเลย', icon: 'fe fe-phone', },

    
    // { url: '/web/promotion', key: 'promotion', title: 'โปรโมชั่น', },
    // { url: '/web/productGroupList', key: 'productGroupList', title: 'สินค้า', },
    // { url: '/web/agent', key: 'agent', title: 'ร่วมเป็นตัวแทนกับเรา', },
    // { url: '/web/contact', key: 'contact', title: 'ติดต่อเรา', },
    // { url: '/web/cart', key: 'cart', title: 'Cart', icon: 'fe fe-shopping-cart', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // {
    //     title: 'Dashboards',
    //     key: 'dashboards',
    //     icon: 'fe fe-home',
    //     roles: ['admin'], 
    //     count: 8,
    //     children: [
    //     {
    //         title: 'Test',
    //         key: 'dashboardTest',
    //         url: '/dashboard/test',
    //     },
    //     ]
    // },
]

export const menuBackConfig = {
    menuBackground: {backgroundColor:"#222323"},
    menuColor1: {color:"#FFF"},
    menuColor2: {color:"#CCC"},
}
export const menuBack = {
    my: {
        title: '',
        roles: [ 'member', 'admin'],
        status: 'active',
        menu: [
            { sector: "sector1", title: "ส่วนตัว" },
            { icon: "fe fe-file-text", to: "../userMyProfile", title: "ข้อมูลส่วนตัว" },
            // { sector: "sector2", title: "จัดการ" },
            // { icon: "fe fe-chevron-right", to: "../policyList", title: "Policy" },
            // { icon: "fe fe-chevron-right", to: "../memberPaymentList", title: "การชำระเงิน" },
            
        ],
    },
    dev: {
        title: 'Dev',
        roles: [ 'dev'],
        status: 'active',
        menu: [
            { icon: "fe fe-users", to: "../userList", title: "User Management" },
            { icon: "fe fe-chevron-right", to: "../testimonialList", title: "Testimonial" },
            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Home Slide" },
            { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            { icon: "fe fe-chevron-right", to: "../videoList", title: "Video" },
            { icon: "fe fe-chevron-right", to: "../videoGroupList", title: "Video Group" },
            { icon: "fe fe-chevron-right", to: "../promotionList", title: "Promotion" },
            { icon: "fe fe-chevron-right", to: "../promotionGroupList", title: "Promotion Group" },
            { icon: "fe fe-chevron-right", to: "../registerLeadList", title: "Customer Register" },
            { icon: "fe fe-chevron-right", to: "../registerServiceList", title: "Register Service" },
            { icon: "fe fe-chevron-right", to: "../budgetList", title: "Budget" },
            { icon: "fe fe-chevron-right", to: "../serviceTypeList", title: "Service Type" },
            { icon: "fe fe-chevron-right", to: "../projectImageList", title: "Project Image" },
        ],
    },
    admin: {
        title: '',
        roles: [ 'admin'],
        status: 'active',
        menu: [
            // { icon: "fe fe-chevron-right", to: "../policyList", title: "Policy" },

            { sector: "sectorUser", title: "Management" },
            { icon: "fe fe-users", to: "../userList", title: "User Management" },
            
            // { sector: "sectorRegister", title: "Registered" },
            // { icon: "fe fe-chevron-right", to: "../registerLeadList", title: "Customer Registered" },

            // { sector: "sectorHome", title: "Home Page Config" },
            // { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Slide" },
            // { icon: "fe fe-chevron-right", to: "../homeContentLeftRightList", title: "Content Left Right" },
            // { icon: "fe fe-chevron-right", to: "../homeCustomerList", title: "Customer" },
            // { icon: "fe fe-chevron-right", to: "../homeCustomerReviewList", title: "Customer Review" },

            { sector: "sectorWebConfig", title: "Web Config" },
            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Home Slide" },
            { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            { icon: "fe fe-chevron-right", to: "../productGroupList", title: "Product Group" },
            // { icon: "fe fe-chevron-right", to: "../videoList", title: "Video" },
            // { icon: "fe fe-chevron-right", to: "../videoGroupList", title: "Video Group" },
            // { icon: "fe fe-chevron-right", to: "../promotionList", title: "Promotion" },
            // { icon: "fe fe-chevron-right", to: "../promotionGroupList", title: "Promotion Group" },

        ],
    },
}

export const route = [
    ...menuMainRoute,
    ...menuDefaultRoute,
    // Custom
    { path: '/userMyProfile', Component: lazy(() => import('app/_custom/pages/userMyProfile')), exact: true,},

    { path: '/devall', Component: lazy(() => import('app/amaraffe/pages/all/list')), exact: true,},
  
    // amaraffe PROJECT ///////////////////////////////////////////////////////////////
  
    // user
    { path: '/userList', Component: lazy(() => import('app/amaraffe/pages/user/list'))},
    { path: '/user/:id', Component: lazy(() => import('app/amaraffe/pages/user/edit'))},
  
    { path: '/web/thankYou', Component: lazy(() => import('app/amaraffe/pages/web/thankYou'))},
    { path: '/web/privacy', Component: lazy(() => import('app/amaraffe/pages/web/privacy'))},

    // admin

    // { path: '/table1List', Component: lazy(() => import('app/amaraffe/pages/table1/list'))},
    // { path: '/table1/:id', Component: lazy(() => import('app/amaraffe/pages/table1/edit'))},
  


    // home
    // { path: '/web/video/:id', Component: lazy(() => import('app/amaraffe/pages/web/videoDetail'))},
    // { path: '/web/promotion/:id', Component: lazy(() => import('app/amaraffe/pages/web/promotionDetail'))},


    // { path: '/web/whyme', Component: lazy(() => import('app/amaraffe/pages/web/whyme'))},
    // { path: '/web/pricing', Component: lazy(() => import('app/amaraffe/pages/web/pricing'))},
  
  
    { path: '/web/home', Component: lazy(() => import('app/amaraffe/pages/web/home'))},
    // { path: '/web/homeRegister', Component: lazy(() => import('app/amaraffe/pages/web/homeRegister'))},
    { path: '/web/about', Component: lazy(() => import('app/amaraffe/pages/web/about'))},
    { path: '/web/contact', Component: lazy(() => import('app/amaraffe/pages/web/contact'))},
    // { path: '/web/promotion', Component: lazy(() => import('app/amaraffe/pages/web/promotion'))},
    // { path: '/web/salePromotion', Component: lazy(() => import('app/amaraffe/pages/web/salePromotion'))},
    // { path: '/web/servicePromotion', Component: lazy(() => import('app/amaraffe/pages/web/servicePromotion'))},
    // { path: '/web/video', Component: lazy(() => import('app/amaraffe/pages/web/video'))},
    // { path: '/web/product/:id', Component: lazy(() => import('app/amaraffe/pages/web/product'))},
    // { path: '/web/productList/:id', Component: lazy(() => import('app/amaraffe/pages/web/productList'))},
    // { path: '/web/productGroupList', Component: lazy(() => import('app/amaraffe/pages/web/productGroupList'))},
    // { path: '/web/savingGold', Component: lazy(() => import('app/amaraffe/pages/web/savingGold'))},
  
    // { path: '/web/blog', Component: lazy(() => import('app/amaraffe/pages/web/blog'))},
    // { path: '/web/agent', Component: lazy(() => import('app/amaraffe/pages/web/agent'))},
    // { path: '/web/cart', Component: lazy(() => import('app/amaraffe/pages/web/cart'))},
    // { path: '/web/checkout', Component: lazy(() => import('app/amaraffe/pages/web/checkout'))},
    // { path: '/web/orderList', Component: lazy(() => import('app/amaraffe/pages/web/order'))},
    // { path: '/web/order/:id', Component: lazy(() => import('app/amaraffe/pages/web/order'))},
    // { path: '/web/orderPayment', Component: lazy(() => import('app/amaraffe/pages/web/orderPayment'))},
  
    
  
]