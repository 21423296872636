import React,{useState} from 'react';
import Helmet from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Compress from "react-image-file-resizer";
// import firebase from '../config/firebase_config';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { fb, db, rdb, auth, storage } from 'services/firebase'
// import { fb, db, rdb, auth, storage, functions } from 'services/firebase'
import { notification } from 'antd';
import moment from 'moment';
import { indexOf, lastIndexOf } from 'lodash';
import { Notify }  from 'line-api'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import DOMPurify from 'dompurify';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import XLSX from "xlsx";  
import XlsxPopulate from 'xlsx-populate'
 
// const functions_host = fb.app().functions('asia-northeast1') // tokyo
// house-th
const functions_host = fb.app().functions('asia-southeast2') // Jakarta, Indonesia
// const functions_host = functions// 

// stats

// lineNotify({token:"r2cfdTsL51bFIqugJtyhfFYYGckMr12qupRJU85IAX5", messages:`your message`})
// googleTagManager({id: "GTM-NTFMB78"})
// googleAnalytic({tracking_id : "UA-184029321-1"})
// facebookPixel({pixel_id: "143207657566547"})

// const nextFunctionCreate = (result) => { 
//   const messages = [
//     `Topic : สนใจโปรโมชั่น`,
//     `Budget  : ${doc.budget || ''}`,
//     `Messages: ${doc.messages || ''}`,
//     `Date  :  ${(new Date()).toString()} `,
//     `\nCustomer Contact`,
//     `Name  : ${doc.name || ''}`,
//     `Mobile: ${doc.mobile || ''}`,
//     `Email : ${doc.email || ''}`,
//     `LINE  : ${doc.lineID || ''}`,
//   ]
//   if(doc)
//     lineNotify({token: 'r2cfdTsL51bFIqugJtyhfFYYGckMr12qupRJU85IAX5', messages: messages.join(`\n`)})
//     // lineNotify({token: 'r2cfdTsL51bFIqugJtyhfFYYGckMr12qupRJU85IAX5', messages: messages.join(`\n`)})
//   history.push(`../web/thankYou`) 
// }
export const lineNotify = (config) => {
  const { token, messages } = config

  var addMessage = functions_host.httpsCallable('lineNotify');
  
  addMessage({ token: token, messages: messages }).then(function func(result) {
    console.log("result " , result);
    // Read result of the Cloud Function.
    // var sanitizedMessage = result.data.messages;
    // console.log( "sanitizedMessage" , sanitizedMessage );
  }).catch(function func(error) {
    console.log('error ', error);
    // // Getting the Error details.
    // var code = error.code;
    // console.log( "code" , code );
    // var message = error.message;
    // console.log( "message" , message );
    // var details = error.details;
    // console.log( "details" , details );
    // ...
  })

  // var full_message = [
  //   "",
  //   "Topic : "+(data.get('topic') != null ? data.get('topic') : ""),
  
  //   "Name  : "+(data.get('name') != null ? data.get('name') : ""),
  //   "Mobile: "+(data.get('mobile') != null ? data.get('mobile') : ""),
  //   "LineID: "+(data.get('lineId') != null ? data.get('lineId') : ""),
  //   "Email : "+(data.get('email') != null ? data.get('email') : ""),
  
  //   "Messages: "+(data.get('messages') != null ? data.get('messages') : "")
  // ].join('\n');
  //   // alert('A name was submitted: ' + full_message);
  // var addMessage = firebase.functions().httpsCallable('lineNotify');
  
  // addMessage({text:full_message}).then(function(result) {
  //   // Read result of the Cloud Function.
  //   var sanitizedMessage = result.data.text;
  //   console.log( "sanitizedMessage" , sanitizedMessage );
  // }).catch(function(error) {
  //   // Getting the Error details.
  //   var code = error.code;
  //   console.log( "code" , code );
  //   var message = error.message;
  //   console.log( "message" , message );
  //   var details = error.details;
  //   console.log( "details" , details );
  //   // ...
  // });
}

export const doSendMailReciept = (mailOptions) => {
  // // Message text passed from the client.
  // const text = data.text;
  // // Authentication / user information is automatically added to the request.
  // const uid = context.auth.uid;
  // const name = context.auth.token.name || null;
  // const picture = context.auth.token.picture || null;
  // const email = context.auth.token.email || null;
  var sendEmail = functions_host.httpsCallable('sendMailReciept');
  sendEmail(mailOptions)
  .then(function func(result) {
    console.log("result " , result);
  }).catch(function func(error) {
    console.log('error ', error);
  })
}

export const googleTagManager = (config) => {
  const { id } = config
  const tagManagerArgs = {
    gtmId: id
  }
  
  TagManager.initialize(tagManagerArgs)
}
export const googleAnalytic = (config) => {
  if (process.env.NODE_ENV === 'development')
    return true
  else{
    const { tracking_id } = config
    // ReactGA.initialize('UA-000000-01');
    const r = ReactGA.initialize(tracking_id);
    console.log("GA > tracking_id = ", tracking_id)
    // console.log("Google Analytics > tracking_id = ", tracking_id)
    // console.log(window.location.pathname + window.location.search)
    // console.log(window.location.href)
    // console.log(window.location.pathname)
    // window.ga('set', 'page', window.location.pathname + window.location.search);
    // window.ga('send', 'pageview');
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.pageview(window.location.href);
  }
}
export const facebookPixel = (config) => {
  const { pixel_id } = config
  const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(pixel_id, advancedMatching, options);

  ReactPixel.pageView(); // For tracking page view
  // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
  // ReactPixel.track('test_event_code', 'TEST95856');
  ReactPixel.track('TEST95856', 'test_event_code');
  // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
  // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
  // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.


  //     <!-- Facebook Pixel Code -->
  // <script>
  // !function(f,b,e,v,n,t,s)
  // {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  // n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  // if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  // n.queue=[];t=b.createElement(e);t.async=!0;
  // t.src=v;s=b.getElementsByTagName(e)[0];
  // s.parentNode.insertBefore(t,s)}(window, document,'script',
  // 'https://connect.facebook.net/en_US/fbevents.js');
  // fbq('init', '143207657566547');
  // fbq('track', 'PageView');
  // </script>
  // <noscript><img height="1" width="1" style="display:none"
  // src="https://www.facebook.com/tr?id=143207657566547&ev=PageView&noscript=1"
  // /></noscript>
  // <!-- End Facebook Pixel Code -->
}

export const sendLineNotify = (token, body) => {
  const { message, sticker, image } = body
  const notify = new Notify({
      token: token
  })
  notify.status().then(console.log)
  notify.status().then(()=>{console.log(notify.ratelimit)})
  notify.send({
      message: 'Test message',
      sticker: 'smile', // shorthand
      // sticker : { packageId: 1, id: 2 } // exact ids
      // image: 'test.jpg', // local file
      // image: { fullsize: 'http://example.com/1024x1024.jpg', thumbnail: 'http://example.com/240x240.jpg' } // remote url
  }).then(console.log)
  // { status: 200, message: 'ok' }
  
}

export const uploadFileSizeLimit = 1024000 // 1MB byte
// export const uploadFileSizeLimit = 512000 // 512 KB byte
// export const uploadFileSizeLimit = 256000 // 256 KB byte
export const defaultTimeformat = 'HH:mm:ss'
export const defaultDateformat = 'DD/MMM/YYYY'
export const defaultDateTimeformat = 'DD/MMM/YYYY HH:mm:ss'
export const defaultTimeZoneOffset = '+0700'
export const defaultPageLimit = 10
export const defaultValue = {
  page:{
    list: {
      showSearch: true,
      pageSize : defaultPageLimit,
      search: { expand: false },
      pageSizeList : [
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 100, value: 100 },
      ],
    }
  },
  formItemLayout: {
    labelCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 8 }, lg: { span: 8 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 12 }, md: { span: 12 }, lg: { span: 12 } }
  },
  formItemLayoutWithOutLabel: {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  },
  formTailLayout: {
    wrapperCol: { xs: { span: 24 }, sm: { span: 12, offset: 12 }, md: { span: 12, offset: 8 }, lg: { span: 12, offset: 8 } }
  },
}
export const defaultCreateBy = (user) =>{ return {createUser_id: user ? user.id : null, createUser: user ? `${user.firstName} ${user.lastName}` : null, createDate: fb.firestore.FieldValue.serverTimestamp()} }
export const defaultUpdateBy = (user) =>{ return {updateUser_id: user ? user.id : null, updateUser: user ? `${user.firstName} ${user.lastName}` : null, updateDate: fb.firestore.FieldValue.serverTimestamp()} }
// new Date(firebase.firestore.Timestamp.now().seconds*1000).toLocaleDateString()

export const secondsToDateFormat = (firestoreTimestampt, dateFormat) => { 
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),dateFormat).utcOffset(defaultTimeZoneOffset) : null
}
export const secondsToTime = (firestoreTimestampt) => { 
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultTimeformat).utcOffset(defaultTimeZoneOffset) : null
}
export const secondsToDate = (firestoreTimestampt) => { 
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultDateformat).utcOffset(defaultTimeZoneOffset) : null
}
export const secondsToDateTime = (firestoreTimestampt) => { 
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultDateTimeformat).utcOffset(defaultTimeZoneOffset) : null
}
export const secondsToDateFormatString = (firestoreTimestampt, dateFormat) => { 
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),dateFormat).utcOffset(defaultTimeZoneOffset).format(dateFormat) : null
}
export const secondsToTimeString = (firestoreTimestampt) => { 
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultTimeformat).utcOffset(defaultTimeZoneOffset).format(defaultTimeformat) : null
}
export const secondsToDateString = (firestoreTimestampt) => { 
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultDateformat).utcOffset(defaultTimeZoneOffset).format(defaultDateformat) : null
}
export const secondsToDateTimeString = (firestoreTimestampt) => { 
  return firestoreTimestampt ? moment(moment.unix(firestoreTimestampt.seconds),defaultDateTimeformat).utcOffset(defaultTimeZoneOffset).format(defaultDateTimeformat) : null
}
export const secToDateString = (seconds) => { 
  return moment(moment.unix(seconds),defaultDateformat).utcOffset(defaultTimeZoneOffset).format(defaultDateformat)
}
export const remainingFromNowInDays = (date) => {
  if(date){
    var date2 = secondsToDate(date)
    var now = new Date()
    // console.log("now = ", now)
    // console.log("date = ", date2)
    const result = getDifferenceInDays(now, date2).toLocaleString(undefined, {maximumFractionDigits:0})
    return result
  }
  else
   return ''
}
export function getDifferenceInDays(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60 * 60 * 24);
}
export function getDifferenceInHours(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60 * 60);
}
export function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60);
}
export function getDifferenceInSeconds(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / 1000;
}

// userLog(
//   {uid: response.user.uid, email: response.user.email, type: 'info', action: 'signIn'}, 
// )
export const userLog = (log, optionData) => {
  const {uid, type, action, messages} = log
  let { email } = log
  if(email === undefined)
    email = '_null_'
  const batch = db.batch()
  if(optionData){
    const {ref, data} = optionData
    batch.set(
      ref,
      data, 
      { merge: true}
    )
  }
  batch.set(
    db.collection("_userLog").doc(email),
    {
      _email: email, 
      _uid: uid || '',
      updateDate: fb.firestore.FieldValue.serverTimestamp(),
      [action || '_']: fb.firestore.FieldValue.increment(1),
    }, 
    { merge: true}
  )
  batch.set(
    db.collection("_userLog").doc(email).collection('log').doc(),
    {
      // _uid: uid,
      // _email: email, 
      createDate: fb.firestore.FieldValue.serverTimestamp(),
      type: type || '',
      action: action || '_', 
      messages: messages || ''
      // processResult: 'success', 
      // messages: 'get my user profile'
    }
  )
  batch.commit()
}

export const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
  });
}
// export const importFileExcel = (file) => {
//   /* Boilerplate to set up FileReader */
//   const reader = new FileReader();
//   const rABS = !!reader.readAsBinaryString;
//   reader.onload = e => {
//     /* Parse data */
//     const bstr = e.target.result;
//     const workBook = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
//     /* Get first worksheet */
//     const sheetName = workBook.SheetNames[0];
//     const sheet = workBook.Sheets[sheetName];
//     // /* Convert array of arrays */
//     // const rows_data = XLSX.utils.sheet_to_json(sheet, {
//     //   header: 1
//     // });
//     // /* Update state */
//     // console.log('rows_data = ',rows_data)

//     // const aoa = XLSX.utils.sheet_add_aoa(sheet,{header: 1})

//     // console.log('aoa = ',aoa)

//     /* notice the hole where cell "B1" would be */
//     // var data = [
//     //   ["Merged1", "", "C", "D"],
//     //   [1,2,3,4],
//     //   ["a","b","c","d"]
//     // ];

//     /* merge cells A1:B1 */
//     // var merge = { s: {r:0, c:0}, e: {r:0, c:1} };
//     // var merge = XLSX.utils.decode_range("A1:B1"); // this is equivalent

//     // format_cell generates the text value for a cell (using number formats).
//     // encode_row / decode_row converts between 0-indexed rows and 1-indexed rows.
//     // encode_col / decode_col converts between 0-indexed columns and column names.
//     // encode_cell / decode_cell converts cell addresses.
//     // encode_range / decode_range converts cell ranges.

//     var data = [
//       ["ใบลงทะเบียน /Registration Form"],
//       ['หลักสูตร /  Subject : MSP - Thai '],
//       ["วันที่ /Date: February 12th, 2020   สถานที่/Venue : Mandarin Hotal"]
//     ];

//     /* generate worksheet */
//     var ws = XLSX.utils.aoa_to_sheet(data);

//     /* add merges */
//     if(!ws['!merges'])ws['!merges'] = [];
//     ws['!merges'].push(XLSX.utils.decode_range("A1:F1"))
//     ws['!merges'].push(XLSX.utils.decode_range("A2:F2"))
//     ws['!merges'].push(XLSX.utils.decode_range("A3:F3"))

//     if(!ws['!cols'])ws['!cols'] = [];
//     // ws['!cols'].push(XLSX.utils.decode_range("A1:F1"))

//     ws['!cols'] = [{ width: 20 }, { width: 20 }, { width: 150 } ]

//     // ws['!cols'] = wscols;

//     // XLSX.utils.encode_cell(cell_address)

//     /* generate workbook */
//     var wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, "sheet1");
//     XLSX.utils.book_append_sheet(wb, sheet, "sheet11");

//     /* generate file and download */
//     const wbout = XLSX.writeFile(workBook, 'ex.xlsx');

//   };
//   if (rABS) reader.readAsBinaryString(file);
//   else reader.readAsArrayBuffer(file);
// }
// export const importFileExcel = (file) => {
//   /* Boilerplate to set up FileReader */
//   const reader = new FileReader();
//   const rABS = !!reader.readAsBinaryString;
//   reader.onload = e => {
//     /* Parse data */
//     // const bstr = e.target.result;
//     // const workBook = XLSX.read(bstr, { type: rABS ? "binary" : "array" })
//     // /* Get first worksheet */
//     // const sheetName = workBook.SheetNames[0];
//     // const sheet = workBook.Sheets[sheetName];
//     const bstr = e.target.result;
//     XlsxPopulate.fromDataAsync(bstr).then(async function (workbook) {
//       console.log("fromDataAsync().then()");
//       //build data you want from workbook
//       const someResult = await Somewhat(workbook, "A1:Z999");
//       console.log("someResult = ",someResult)
//     });
//   };
//   if (rABS) reader.readAsBinaryString(file);
//   else reader.readAsArrayBuffer(file);
// }

function onFileInputChange(files) {
  // const files = evt.target.files;
  // const f = files[0];
  const f = files;
  const reader = new FileReader();
  reader.onload = function (e) {
    console.log("onFileInputChange: reader.onload()");
    // setResult(["Parsing xlsx..."]);
    // Parse data
    const bstr = e.target.result;
    XlsxPopulate.fromDataAsync(bstr).then(async function (workbook) {
      console.log("fromDataAsync().then()");
      //build data you want from workbook
      const someResult = await Somewhat(workbook, "A1:Z999");
      console.log("someResult = ",someResult)
    });
  };
  if (f instanceof Blob) {
    reader.readAsArrayBuffer(f);
  }
}
async function Somewhat(workbook, myRange) {
  console.log("Somewhat():Range: " + myRange);
  const sheet1 = workbook.sheet(0);
  const range = sheet1.range(myRange);
  const sheet_array = range.value();
  //Sample: build into Assoc by col-A & Row-1
  const data1 = {};
  const _cols = sheet_array.shift();
  sheet_array.forEach(rowIdx =>{
    const _row = rowIdx;
    const _key = _row[0];
    if (!_key) {
      console.log('continue')
    }
    if (!data1[_key]) {
      data1[_key] = [];
    }
    if (_row.join("") === "") {
      console.log('continue')
    }
    //
    const plainValues = {};
    _cols.forEach(colIdx => {
      const myCell = _row[colIdx];
      const colName = _cols[colIdx];
      if (!myCell) {
        console.log('continue')
      }
      // "instanceof" won't work on Cell..
      if (myCell.text) {
        //for RichText
        plainValues[colName] = myCell.text();
      } else {
        plainValues[colName] = myCell;
      }
    })
    data1[_key] = plainValues;
  })
  return data1;
}


export const exportFileExcel = (fileName, dataList, nameList, docHeader, docFooter) => {
  const newList = [] 
  if(docHeader && docHeader.length > 0){
    docHeader.forEach(item => {
      newList.push([item.name, item.value])
    })
    newList.push([])
  }
  const titleNameList = []
  nameList.forEach(key => {
    if(Array.isArray(key))
      titleNameList.push(key[1])
    else
      titleNameList.push(key)
  })
  // newList.push(nameList)
  // console.log('nameList = ',nameList)
  newList.push(titleNameList)
  dataList.forEach(data => {
    const row = []
    nameList.forEach(key => {
      // console.log('dd1 = ', key)
      // console.log('dd2 = ', data[key])
      if(Array.isArray(key) && (data[key[0]] === undefined || data[key[0]] === 'undefined') && key[2] !== undefined)
        row.push(key[2])
      else if(Array.isArray(key) && data[key[0]] === undefined || data[key[0]] === 'undefined')
        row.push('')
      else if(Array.isArray(key) && key[0].indexOf('Date') !== -1){
        const dateFormat = key[3] ? key[3].dateFormat : null
        // console.log(key[0], moment(moment.unix(data[key[0]]),defaultDateTimeformat).utcOffset(defaultTimeZoneOffset).format(defaultDateTimeformat))
        // console.log(key[0], moment(moment.unix(data[key[0]]),dateFormat).utcOffset(defaultTimeZoneOffset).format(dateFormat))
        // console.log(key[0], moment(moment.unix(data[key[0]]),dateFormat).utcOffset(defaultTimeZoneOffset).format(defaultDateTimeformat))
        // console.log(key[0], moment(moment.unix(data[key[0]]),defaultDateTimeformat).utcOffset(defaultTimeZoneOffset).format(defaultDateTimeformat))
        // console.log(key[0], dateFormat)
        let dateValue = ""
        if(dateFormat && dateFormat !== null)
          // dateValue = moment(moment.unix(data[key[0]].seconds),defaultDateTimeformat).utcOffset(defaultTimeZoneOffset).format(dateFormat)
          dateValue = secondsToDateFormatString(data[key[0]], dateFormat)
        else
          dateValue = secondsToDateTimeString(data[key[0]])
        row.push(dateValue)
      }
      else if(Array.isArray(key)){
        // console.log('key = ', key)
        // console.log('data[key[0]] = ', data[key[0]])
        const option = key[3] ? key[3].option : null
        if(option === 'trim')
          row.push(data[key[0]].trim())
        else
          row.push(data[key[0]])
      }
      else if(data[key] === undefined || data[key] === 'undefined')
        row.push('')
      else if(data[key] && key.indexOf('Date') !== -1){
        const dateValue = secondsToDateTimeString(data[key])
        // console.log('dateValue = ', dateValue)
        row.push(dateValue)
      }
      else
        row.push(data[key])
    })
    newList.push(row)
    // console.log('bb = ', bb)
  })
  newList.push(docFooter)
  // dataList.map(p => [p.firstName, p.companyName])
  
  // const newDataList = [nameList].concat(newList)
  console.log('newList = ', newList)
  console.log(fileName)
  /* convert state to workbook */
  const ws = XLSX.utils.aoa_to_sheet(newList)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, fileName)
  // XLSX.writeFile(wb, "exportFile.xlsx")
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"
]
.map(function(x) {
  return "." + x;
})
.join(",");

/* generate an array of column objects */
const make_cols = (refstr, startAt) => {
const o = []
const C = XLSX.utils.decode_range(refstr).e.c + 1;
for (var i = 0; i < C; i+=1) 
  if(i >= startAt)
    o[i] = { name: XLSX.utils.encode_col(i), key: i };
return o;
};

export const importFileExcel = (file, setColAll, setColHeader, setColBody, setColumns, setCols /*:File*/) => {
  /* Boilerplate to set up FileReader */
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result;
    const workBook = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const sheetName = workBook.SheetNames[0];
    const sheet = workBook.Sheets[sheetName];
    /* Convert array of arrays */
    const rows_data = XLSX.utils.sheet_to_json(sheet, {
      header: 1
    });
    /* Update state */
    // console.log('rows_data = ',rows_data)
    setColAll(rows_data)

    const header = rows_data[0];
    setColHeader(rows_data[0])
    setColBody(rows_data.slice(1).filter(p => p[0] !== undefined))
    // console.log('ccc = ', rows_data.slice(1).filter(p => p[0] !== undefined))
    const columnList = []
    console.log('header = ', header)
    Object.keys(header).forEach(key =>{
      const abc = { title: header[key], key: key, render: (data) => 
      <>
        {
          data[key]
        }
      </>, }
      
      columnList.push(abc)
    })
    setColumns(columnList)
    setCols(make_cols(sheet["!ref"],0))
    // console.log('sheet["!ref"] = ', sheet["!ref"])
    // console.log(make_cols(sheet["!ref"],0))
    // setCols(rows_data[0])
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
}

export const importFileExcel2 = (file, sheetName, config, setColAll, setColHeader, setColBody, setColumns) => {
  const headerRow = config.headerRow || 1
  const buttomRowDelete = config.buttomRowDelete || 0
  // const headerDAO = {a: 1, b: 2, c: 3, d: 4, e:5, f:6, g:7, h:8, i:9, j:10, k:11, l:12, m:13, n:14, o:15, p:16, q:17, r:18, s:19, t:20, u:21, v:22, w:23, x:24, y:25, z:26}
  const headerDAO = config.headerDAO || {}
  const valueSpecific = config.valueSpecific || {}
  // const rowDefault = {chapter_id: 1, chapter: 'Integrity'}
  // Object.keys(headerDAO).forEach(key =>{
  //   console.log(`${key} / ${key.charCodeAt(0) - 97} `)
  // })
  
  /* Boilerplate to set up FileReader */
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = e => {
    /* Parse data */
    const bstr = e.target.result;
    const workBook = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const sheetName1 = sheetName && sheetName !== null ? sheetName : workBook.SheetNames[0]
    const sheet = workBook.Sheets[sheetName1]
    /* Convert sheet to json */
    const rows_data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    /* Update state */
    console.log('rows_data = ',rows_data)
    setColAll(rows_data)


    const header = rows_data[headerRow-1]
    if(config && config.defaultColumn){
      console.log(config.defaultColumn)
      console.log({...Object.keys(config.defaultColumn)})

      Object.keys(config.defaultColumn).forEach(key => {
        header.push(key)
      })
      console.log(header)
    }

    // rename header parameter DAO
    Object.keys(headerDAO).forEach(key => {
      // console.log('typeof key ', typeof key)
      // console.log(Number.isNaN(parseInt(key,0)))
      // console.log(`${key} / ${key.charCodeAt(0) - 97} `)
      const rowKey = Number.isNaN(parseInt(key,0)) ? (key.charCodeAt(0) - 97) : key-1
      header[rowKey] = headerDAO[key]
    })
    // specific value 
    console.log('-- value specific --')
    // chapterName, dateFrom, dateTo
    let valueSpecificValue = {}
    Object.keys(valueSpecific).forEach(key => {
      console.log(key, valueSpecific[key])
      const r = valueSpecific[key].r - 1
      const c = valueSpecific[key].c.charCodeAt(0) - 97
      console.log(key, rows_data[r][c])
      valueSpecificValue = {...valueSpecificValue, [key]: rows_data[r][c]}
    })
    if(config.setValueSpecific)
      config.setValueSpecific(valueSpecificValue)
    setColHeader(header)
    const rowWithOutHeader = rows_data.slice(headerRow).filter(p => p[0] !== undefined)
    const rowWithOutButtom = rowWithOutHeader.splice(0,rowWithOutHeader.length - buttomRowDelete)
    if(config && config.defaultColumn){
      rowWithOutButtom.forEach(item => {
        Object.keys(config.defaultColumn).forEach(key => {
          item.push(config.defaultColumn[key])
        })
      })
    }
    setColBody(rowWithOutButtom)
    // console.log('ccc = ', rows_data.slice(1).filter(p => p[0] !== undefined))
    const columnList = []
    console.log('header = ', header)
    Object.keys(header).forEach(key =>{
      const abc = { title: header[key], key: key, render: (data) => 
      <>
        { data[key] }
      </>, }
      columnList.push(abc)
    })
    setColumns(columnList)
    // setCols(make_cols(sheet["!ref"],0))
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
}

const dateValueToDate = (dateValue) => {
  // const excelDateValue = 44774;
  console.log('type = ', typeof(dateValue))
  const excelDateValue = parseFloat(dateValue)
  console.log('excel = ', excelDateValue)
  const unixDate = (excelDateValue - 25569) * 86400;
  console.log("unix =", unixDate);
  // const date = moment(moment.unix(unixDate)).format(defaultDateformat)
  const date = moment.unix(unixDate)
  console.log("date conv = ", date)
  return date
};

export const importFileExcelUpdateData = async (colHeader, colBody, config, tableTarget, master, user, nextFunction) => {
  var batch = db.batch();
  const userNotFound = []
  const actionList = []
  if(colBody.length > 0){
    const docData = []
    for (let i = 0; i < colBody.length; i+=1) {
        const row = {}
        for (let j = 0; j < colBody[0].length; j+=1) {
          const key = colHeader[j]
          const value = colBody[i][j]
          if(key === 'powerTeam'){
            row[key] = value != null ? value : "" ;
            const powerTeam = master.powerTeam.filter(p => p.label === value)
            if(powerTeam[0]){
              row['powerTeam_id'] = powerTeam && powerTeam[0].value
            }
            else
              openNotificationWithIcon('error','powerTeam name wrong please check are correct data',`error line no ${i+2}`)
          }
          else if(key === 'chapter'){
            row[key] = value != null ? value : "" ;
            // console.log('value =',value)
            // console.log('master =',master.chapter)
            const chapter = master.chapter.filter(p => p.label === value)
            if(chapter[0]){
              // console.log(chapter[0])
              row['chapter_id'] = chapter && chapter[0].value
            }
            else
              openNotificationWithIcon('error','Chapter name wrong please check are correct data',`error line no ${i+2}`)
          }
          else if(key === 'whtType_id'){
            row[key] = value != null ? value.toString() : "" 
            const whtType = master.whtType.filter(p => p.value === row[key])
            if(whtType[0])
              row['whtType'] = whtType && whtType[0].label
          }
          else if(key && key.indexOf('Date') !== -1){
          // else if(key === 'renewalDate'){
            console.log(key,value)
            console.log(key,moment(value,'DD/MM/YYYY').toDate())
            if(value){
              if(typeof(value) === 'string')
                row[key] = moment(value,'DD/MM/YYYY').toDate()
              else if(typeof(value) === 'number') {
                const dateConverted = dateValueToDate(value)
                // console.log('dateConverted = ', dateConverted)
                const toDate = moment(dateConverted, 'DD/MM/YYYY').toDate()
                console.log('toDate = ', toDate)
                row[key] = toDate
              }
            }else
              row[key] = ""
            
            // row[key] = value != null ? moment(value,'DD/MM/YYYY').toDate() : "" ;
          }
          else
            row[key] = value != null ? value : "" ;
          // console.log(i+" "+ colBody[0][j] +" /"+j+" / "+element)
          
        }
        
        docData[i] = row

        var user_query = db.collection(tableTarget)
          .where('chapter','==',row.chapter)
          .where('firstName','==',row.firstName)
          .where('lastName','==',row.lastName)
          // .where('status','==',"active")
        // if(config && config.onlyActiveMember){
        //   user_query = user_query.where('status','==',"active")
        // }

        const row2 = {...row, 
          fullName_keywords: createKeywordsList([row.firstName, row.lastName]),
          status: "active",
          status_id: "active",
          createDate: fb.firestore.FieldValue.serverTimestamp(),
          createUser : `import by ${user ? `${user.firstName} ${user.lastName}` : ''}`,
        }

        const row2_when_create = {...row, 
          role: "member",
          role_id: "member",
        }

        if(row.status === 'delete'){
          await user_query.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.ref.delete();
              console.info(`deleted user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              // batch.delete(doc.ref)
              actionList.push({action: 'delete', ref: doc.ref})
            })
          })
        }
        else if(row.status === 'create' || row.status === 'update' || row.status === 'merge'){
          
          const dataMissing = checkUserDataForBilling(row)
          if( dataMissing.length > 0 ){
            openNotificationWithIcon('error',`${row.chapter} / ${row.firstName} ${row.lastName} / Data Incomplete For Billing `,`กรุณากรอกข้อมูล ${dataMissing}`)
            return false
          }

          await user_query.onSnapshot(function func(snapshot){
            const resultSize = snapshot.docs.length
            console.log(`${tableTarget} size = `, resultSize)
            if(resultSize === 0 && row.status === 'create'){
              console.info(`added user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              // db.collection(tableTarget).add(row2)
              // batch.set(db.collection(tableTarget).doc(), row2)
              actionList.push({action: 'create', ref: db.collection(tableTarget).doc(), data: {...row2, ...row2_when_create}})
            }
            if((resultSize === 1 && row.status === 'update') || (resultSize === 1 && row.status === 'merge')){
              console.log('status = ', row.status)
              const result = snapshot.docs.map(doc1 => ({...doc1.data(), id:doc1.id}))
              const id = result[0].id
              // console.log(`id = `, id)
              // db.collection(tableTarget).doc(id).update(row2)
              console.info(`${row.status} user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              // batch.set(db.collection(tableTarget).doc(id), row2)
              actionList.push({action: row.status, ref: db.collection(tableTarget).doc(id), data: row2})
            }
            if((resultSize === 0 && row.status === 'update') || (resultSize === 0 && row.status === 'merge')){
              userNotFound.push({chapter: row.chapter, firstName: row.firstName, lastName: row.lastName})
              // console.log('userNotFound = ',userNotFound)
              console.info(`can't ${row.status} user (${row.chapter} / ${row.firstName} / ${row.lastName}) because this user not found`)
            }
          })
        }
        // checkUpdate(batch, row)
    }
    

    // console.log(docData) 
  }

  setTimeout((function() {
    // return process.exit(22);
    console.log('actionList = ', actionList)
    console.log('actionList = ', actionList.length)
    actionList.forEach((item) => {
      console.log('action = ', item)
      if(item.action === 'create')
        batch.set(item.ref, item.data)
      else if(item.action === 'merge')
        batch.set(item.ref, item.data, {merge: true})
      else if(item.action === 'update')
        batch.set(item.ref, item.data)
      else if(item.action === 'delete')
        batch.delete(item.ref)
    })
    // Commit the batch
    batch.commit().then(function () {
      console.log("batch.commit()")
      openNotificationWithIcon('success','Import',`Complete`)
      // history.push(`/userList`)
    });

    console.log('userNotFound.length ', userNotFound.length)
    if(userNotFound.length > 0){
      let userNameList = ""
      userNotFound.forEach(p => {
        userNameList += `\n${p.chapter} / ${p.firstName} ${p.lastName}`
      })

      // openNotificationWithIcon('warning','Can\'t update/merge user, because User Not Found',userNameList)
      openNotificationWithIcon('warning','Can\'t update/merge user, because User Not Found',
        <>
          {
            userNotFound.map(item => 
              <>
                {item.chapter} / {item.firstName} / {item.lastName}<br />
              </>
            )
          }
        </>
      )
    }
    nextFunction()
  }), 2000);
  // document.location.href = '/#/userList';
  // window.location.href = '/#/userList'
}
const getMasterData = (master, masterName, data, dataKey) => {
  if(master && masterName && master === masterName){
    console.log(master,master[masterName])
    const result = master[masterName].filter(p => p[dataKey || 'label'] === data)
    return (result && result[0]) ? result[0] : null
  } else 
    return null
}

const getMasterDataLable = (master, masterName, data) => {
  const result = getMasterData(master, masterName, data, 'value')
  return (result && result !== null) ? result['label'] : null
}

const getMasterDataValue = (master, masterName, data) => {
  const result = getMasterData(master, masterName, data, 'label')
  console.log(masterName, result)
  return (result && result !== null) ? result['value'] : null
}

const getMasterDataOption = (master, masterName, data, dataKey, optionDataKey) => {
  const result = getMasterData(master, masterName, data, dataKey || 'label')
  // console.log(masterName, result)
  return (result && result !== null) ? result['option'][optionDataKey] : null
}

const getMasterIdToRow = (row, i, master, masterName, key, value) => {
  if(key === masterName){
    row[key] = value != null ? value : ""
    const result = getMasterDataValue(master, masterName, value)
    if(result !== null)
      row[`${masterName}_id`] = result
    else
      openNotificationWithIcon('error',`${key} = ${value} data not found`,`Error line no ${i+2}`)
    return true
  } else
    return false
}

export const importFileExcelUpdateData_bni_member = async (colHeader, colBody, config, tableTarget, master, user, nextFunction) => {
  var batch = db.batch();
  const userNotFound = []
  const actionList = []
  if(colBody.length > 0){
    const docData = []
    for (let i = 0; i < colBody.length; i+=1) {
      // if(i >0){
        const row = {}//colBody[i];
        // row[i] = {}
        for (let j = 0; j < colBody[0].length; j+=1) {
          // const key = colBody[0][j];
          const key = colHeader[j]
          const value = colBody[i][j]
          // row[j] = {}
          if(getMasterIdToRow(row, i, master, 'powerTeam', key, value)) 
            console.log(key, 'powerTeam')
          else if(getMasterIdToRow(row, i, master, 'chapter', key, value)) 
            console.log(key, 'chapter')
          else if(key === 'whtType_id'){
            row[key] = value != null ? value.toString() : "" 
            const result = getMasterDataLable(master, 'whtType_id', value)
            if(result !== null)
              row['whtType'] = result
          }else if(key && key.indexOf('Date') !== -1){
            console.log(key,value)
            console.log(key,moment(value,'DD/MM/YYYY').toDate())
            if(value){
              if(typeof(value) === 'string')
                row[key] = moment(value,'DD/MM/YYYY').toDate()
              else if(typeof(value) === 'number') {
                const dateConverted = dateValueToDate(value)
                const toDate = moment(dateConverted, 'DD/MM/YYYY').toDate()
                console.log('toDate = ', toDate)
                row[key] = toDate
              }
            } else
              row[key] = ""
          } else
            row[key] = value != null ? value : "" ;
        }
        
        docData[i] = row

        var user_query = db.collection(tableTarget)
          .where('chapter','==',row.chapter)
          .where('firstName','==',row.firstName)
          .where('lastName','==',row.lastName)
          // .where('status','==',"active")
        // if(config && config.onlyActiveMember){
        //   user_query = user_query.where('status','==',"active")
        // }

        const row2 = {...row, 
          fullName_keywords: createKeywordsList([row.firstName, row.lastName]),
          status: "active",
          status_id: "active",
          createDate: fb.firestore.FieldValue.serverTimestamp(),
          createUser : `import by ${user ? `${user.firstName} ${user.lastName}` : ''}`,
        }

        const row2_when_create = {...row, 
          role: "member",
          role_id: "member",
          status: "active",
          status_id: "1",
        }

        if(row.status === 'delete'){
          await user_query.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              // doc.ref.delete();
              console.info(`deleted user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              // batch.delete(doc.ref)
              actionList.push({action: 'delete', ref: doc.ref})
            })
          })
        }
        else if(row.status === 'create' || row.status === 'update' || row.status === 'merge'){
          
          const dataMissing = checkUserDataForBilling(row)
          if( dataMissing.length > 0 ){
            openNotificationWithIcon('error',`${row.chapter} / ${row.firstName} ${row.lastName} Data Incomplete For Billing `,`กรุณากรอกข้อมูล ${dataMissing}`)
            return false
          }

          await user_query.onSnapshot(function func(snapshot){
            const resultSize = snapshot.docs.length
            console.log(`${tableTarget} size = `, resultSize)
            if(resultSize === 0 && row.status === 'create'){
              console.info(`added user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              // db.collection(tableTarget).add(row2)
              // batch.set(db.collection(tableTarget).doc(), row2)
              actionList.push({action: 'create', ref: db.collection(tableTarget).doc(), data: {...row2, ...row2_when_create}})
            }
            if((resultSize === 1 && row.status === 'update') || (resultSize === 1 && row.status === 'merge')){
              console.log('status = ', row.status)
              const result = snapshot.docs.map(doc1 => ({...doc1.data(), id:doc1.id}))
              const id = result[0].id
              // console.log(`id = `, id)
              // db.collection(tableTarget).doc(id).update(row2)
              console.info(`${row.status} user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              // batch.set(db.collection(tableTarget).doc(id), row2)
              actionList.push({action: row.status, ref: db.collection(tableTarget).doc(id), data: row2})
            }
            if((resultSize === 0 && row.status === 'update') || (resultSize === 0 && row.status === 'merge')){
              userNotFound.push({chapter: row.chapter, firstName: row.firstName, lastName: row.lastName})
              // console.log('userNotFound = ',userNotFound)
              console.info(`can't ${row.status} user (${row.chapter} / ${row.firstName} / ${row.lastName}) because this user not found`)
            }
          })
        }
        // checkUpdate(batch, row)
    }
    

    // console.log(docData) 
  }

  setTimeout((function() {
    // return process.exit(22);
    console.log('actionList = ', actionList)
    console.log('actionList = ', actionList.length)
    actionList.forEach((item) => {
      console.log('action = ', item)
      if(item.action === 'create')
        batch.set(item.ref, item.data)
      else if(item.action === 'merge')
        batch.set(item.ref, item.data, {merge: true})
      else if(item.action === 'update')
        batch.set(item.ref, item.data)
      else if(item.action === 'delete')
        batch.delete(item.ref)
    })
    // Commit the batch
    batch.commit().then(function () {
      console.log("batch.commit()")
      openNotificationWithIcon('success','Import',`Complete`)
      // history.push(`/userList`)
    });

    console.log('userNotFound.length ', userNotFound.length)
    if(userNotFound.length > 0){
      let userNameList = ""
      userNotFound.forEach(p => {
        userNameList += `\n${p.chapter} / ${p.firstName} ${p.lastName}`
      })

      // openNotificationWithIcon('warning','Can\'t update/merge user, because User Not Found',userNameList)
      openNotificationWithIcon('warning','Can\'t update/merge user, because User Not Found',
        <>
          {
            userNotFound.map(item => 
              <>
                {item.chapter} / {item.firstName} / {item.lastName}<br />
              </>
            )
          }
        </>
      )
    }
    nextFunction()
  }), 2000);
  // document.location.href = '/#/userList';
  // window.location.href = '/#/userList'
}

export const importFileExcelUpdateData_bni_tl = async (colHeader, colBody, config, tableTarget, master, user, nextFunction) => {
  var batch = db.batch();
  const userNotFound = []
  const actionList = []
  if(colBody.length > 0){
    const docData = []
    for (let i = 0; i < colBody.length; i+=1) {
      // if(i >0){
        const row = {}//colBody[i];
        // row[i] = {}
        for (let j = 0; j < colBody[0].length; j+=1) {
          // const key = colBody[0][j];
          const key = colHeader[j]
          const value = colBody[i][j]
          // row[j] = {}
          if(key === 'powerTeam'){
            row[key] = value != null ? value : "" ;
            const powerTeam = master.powerTeam.filter(p => p.label === value)
            if(powerTeam[0]){
              // console.log(chapter[0])
              row['powerTeam_id'] = powerTeam && powerTeam[0].value
            }
            else
              openNotificationWithIcon('error','powerTeam name wrong please check are correct data',`error line no ${i+2}`)
          }
          else if(key === 'chapter'){
            row[key] = value != null ? value : "" ;
            // console.log('value =',value)
            // console.log('master =',master.chapter)
            const chapter = master.chapter.filter(p => p.label === value)
            if(chapter[0]){
              // console.log(chapter[0])
              row['chapter_id'] = chapter && chapter[0].value
            }
            else
              openNotificationWithIcon('error','Chapter name wrong please check are correct data',`error line no ${i+2}`)
          }
          else if(key === 'whtType_id'){
            row[key] = value != null ? value.toString() : "" 
            const whtType = master.whtType.filter(p => p.value === row[key])
            if(whtType[0])
              row['whtType'] = whtType && whtType[0].label
          }
          else if(key && key.indexOf('Date') !== -1){
          // else if(key === 'renewalDate'){
            console.log(key,value)
            console.log(key,moment(value,'DD/MM/YYYY').toDate())
            if(value){
              if(typeof(value) === 'string')
                row[key] = moment(value,'DD/MM/YYYY').toDate()
              else if(typeof(value) === 'number') {
                const dateConverted = dateValueToDate(value)
                // console.log('dateConverted = ', dateConverted)
                const toDate = moment(dateConverted, 'DD/MM/YYYY').toDate()
                console.log('toDate = ', toDate)
                row[key] = toDate
              }
            }else
              row[key] = ""
            
            // row[key] = value != null ? moment(value,'DD/MM/YYYY').toDate() : "" ;
          }
          else
            row[key] = value != null ? value : "" ;
          // console.log(i+" "+ colBody[0][j] +" /"+j+" / "+element)
          
        }
        
        docData[i] = row

        var user_query = db.collection(tableTarget)
          .where('chapter','==',row.chapter)
          .where('firstName','==',row.firstName)
          .where('lastName','==',row.lastName)

        const row2 = {...row, 
          fullName_keywords: createKeywordsList([row.firstName, row.lastName]),
          createDate: fb.firestore.FieldValue.serverTimestamp(),
          createUser : `import by ${user ? `${user.firstName} ${user.lastName}` : ''}`,
        }

        const row2_when_create = {...row, 
          role: "member",
          role_id: "member",
        }

        if(row.status === 'delete'){
          await user_query.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              console.info(`deleted user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              actionList.push({action: 'delete', ref: doc.ref})
            })
          })
        }
        else if(row.status === 'create' || row.status === 'update' || row.status === 'merge'){
          
          await user_query.onSnapshot(function func(snapshot){
            const resultSize = snapshot.docs.length
            console.log(`${tableTarget} size = `, resultSize)
            if(resultSize === 0 && row.status === 'create'){
              console.info(`added user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              actionList.push({action: 'create', ref: db.collection(tableTarget).doc(), data: {...row2, ...row2_when_create}})
            }
            if((resultSize === 1 && row.status === 'update') || (resultSize === 1 && row.status === 'merge')){
              console.log('status = ', row.status)
              const resultList = snapshot.docs.map(doc1 => ({...doc1.data(), id:doc1.id}))
              const result = resultList[0]
              const id = result.id
              console.info(`${row.status} user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              actionList.push({action: row.status, ref: db.collection(tableTarget).doc(id), data: {...row2, status: result.status, status_id: result.status_id}})
            }
            if((resultSize === 0 && row.status === 'update') || (resultSize === 0 && row.status === 'merge')){
              userNotFound.push({chapter: row.chapter, firstName: row.firstName, lastName: row.lastName})
              console.info(`can't ${row.status} user (${row.chapter} / ${row.firstName} / ${row.lastName}) because this user not found`)
            }
          })
        }
        // checkUpdate(batch, row)
    }
    

    // console.log(docData) 
  }

  setTimeout((function() {
    // return process.exit(22);
    console.log('actionList = ', actionList)
    console.log('actionList = ', actionList.length)
    actionList.forEach((item) => {
      console.log('action = ', item)
      if(item.action === 'create')
        batch.set(item.ref, item.data)
      else if(item.action === 'merge')
        batch.set(item.ref, item.data, {merge: true})
      else if(item.action === 'update')
        batch.set(item.ref, item.data)
      else if(item.action === 'delete')
        batch.delete(item.ref)
    })
    // Commit the batch
    batch.commit().then(function () {
      console.log("batch.commit()")
      openNotificationWithIcon('success','Import',`Complete`)
      // history.push(`/userList`)
    });

    console.log('userNotFound.length ', userNotFound.length)
    if(userNotFound.length > 0){
      let userNameList = ""
      userNotFound.forEach(p => {
        userNameList += `\n${p.chapter} / ${p.firstName} ${p.lastName}`
      })

      openNotificationWithIcon('warning','Can\'t update/merge user, because User Not Found',
        <>
          {
            userNotFound.map(item => 
              <>
                {item.chapter} / {item.firstName} / {item.lastName}<br />
              </>
            )
          }
        </>
      )
    }
    nextFunction()
  }), 2000);
}

export const importFileExcelUpdateData_bni_palms = async (colHeader, colBody, config, tableTarget, master, user, nextFunction) => {
  var batch = db.batch();
  const userNotFound = []
  const actionList = []
  
  if(config && config.defaultColumn){
    Object.keys(config.defaultColumn).forEach(key => {
      colHeader.push(key)
    })
    colBody.forEach(item => {
      Object.keys(config.defaultColumn).forEach(key => {
        item.push(config.defaultColumn[key])
      })
    })
    console.log('colHeader',colHeader)
    console.log('colBody',colBody)
  }

  if(colBody.length > 0){
    const docData = []
    for (let i = 0; i < colBody.length; i+=1) {
        const row = {}
        for (let j = 0; j < colBody[0].length; j+=1) {
          const key = colHeader[j]
          const value = colBody[i][j]
          if(key === 'powerTeam'){
            row[key] = value != null ? value : "" ;
            const powerTeam = master.powerTeam.filter(p => p.label === value)
            if(powerTeam[0]){
              row['powerTeam_id'] = powerTeam && powerTeam[0].value
            }
            else
              openNotificationWithIcon('error','powerTeam name wrong please check are correct data',`error line no ${i+2}`)
          }
          else if(key === 'chapter'){
            row[key] = value != null ? value : "" ;
            const chapter = master.chapter.filter(p => p.label === value)
            if(chapter[0]){
              row['chapter_id'] = chapter && chapter[0].value
            }
            else
              openNotificationWithIcon('error','Chapter name wrong please check are correct data',`error line no ${i+2}`)
          }
          else if(key === 'whtType_id'){
            row[key] = value != null ? value.toString() : "" 
            const whtType = master.whtType.filter(p => p.value === row[key])
            if(whtType[0])
              row['whtType'] = whtType && whtType[0].label
          }
          else if(key && key.indexOf('Date') !== -1){
            console.log(key,value)
            console.log(key,moment(value,'DD/MM/YYYY').toDate())
            if(value){
              if(typeof(value) === 'string')
                row[key] = moment(value,'DD/MM/YYYY').toDate()
              else if(typeof(value) === 'number') {
                const dateConverted = dateValueToDate(value)
                const toDate = moment(dateConverted, 'DD/MM/YYYY').toDate()
                console.log('toDate = ', toDate)
                row[key] = toDate
              }
            }else
              row[key] = ""
          }
          else
            row[key] = value != null ? value : "" ;
        }
        
        docData[i] = row

        var user_query = db.collection('user')
          .where('chapter','==',row.chapter)
          .where('firstName','==',row.firstName)
          .where('lastName','==',row.lastName)

        const row2 = {...row, 
          fullName_keywords: createKeywordsList([row.firstName, row.lastName]),
          createDate: fb.firestore.FieldValue.serverTimestamp(),
          createUser : `import by ${user ? `${user.firstName} ${user.lastName}` : ''}`,
        }

        const row2_when_create = {...row, 
          status: "active", status_id: "1",
          role: "member",
          role_id: "member",
          chapter_id: "1",
          chapter: "Integrity",
          powerTeam: 'New Member',
          powerTeam_id: '00',

          gs2021BusinessGoal: 0,
          gs2021BniGoal: 1000000,
          gs2021PayPerCustomer: 100000,
          gs2021ReferalChange: 50,
          gs2021TotalCustomer: 10,
          gs2021TotalRef: 20,
          gs2021TotalRefPerWeek: 20/50,
          gs2021Total121PerWeek: 20/50,
        }

        if(row.status === 'delete'){
          await user_query.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              console.info(`deleted user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              actionList.push({action: 'delete', ref: doc.ref})
            })
          })
        }
        else if(row.status === 'create' || row.status === 'update' || row.status === 'merge'){
          await user_query.onSnapshot(function func(snapshot){
            const resultSize = snapshot.docs.length
            console.log(`${tableTarget} size = `, resultSize)
            if(resultSize === 0 && row.status === 'create'){
              console.info(`added user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              actionList.push({action: 'create', ref: db.collection(tableTarget).doc(), data: {...row2, ...row2_when_create}})
            }
            if((resultSize === 1 && row.status === 'update') || (resultSize === 1 && row.status === 'merge')){
              const resultList = snapshot.docs.map(doc1 => ({...doc1.data(), id:doc1.id}))
              const result = resultList[0]
              const id = result.id
              console.info(`${row.status} user (${row.chapter} / ${row.firstName} / ${row.lastName})`)
              let docId = id
              let rowNew = {...row2, }
              if(config && config.defaultIdFormat === "id_with_meetingDate"){
                docId = `${id}_${config.defaultColumn.years.toString()}_${config.defaultColumn.weeks.toString()}`
                rowNew = {...rowNew, user_id: id, status: result.status, status_id: result.status}
              }
              console.log('docId = ', docId)
              actionList.push({action: row.status, ref: db.collection(tableTarget).doc(docId), data: rowNew,})
            }
            if((resultSize === 0 && row.status === 'update') || (resultSize === 0 && row.status === 'merge')){
              userNotFound.push({chapter: row.chapter, firstName: row.firstName, lastName: row.lastName})
              actionList.push({action: 'create', ref: db.collection(tableTarget).doc(), data: {...row2, ...row2_when_create}})
              console.info(`can't ${row.status} user (${row.chapter} / ${row.firstName} / ${row.lastName}) because this user not found`)
            }
          })
        }
    }
  }

  setTimeout((function() {
    console.log('actionList = ', actionList)
    actionList.forEach((item) => {
      console.log('action = ', item)
      if(item.action === 'create')
        batch.set(item.ref, item.data)
      else if(item.action === 'merge')
        batch.set(item.ref, item.data, {merge: true})
      else if(item.action === 'update')
        batch.set(item.ref, item.data)
      else if(item.action === 'delete')
        batch.delete(item.ref)
    })
    batch.commit().then(function () {
      console.log("batch.commit()")
      openNotificationWithIcon('success','Import',`Complete`)
    }).catch((error) => {
      console.log("Transaction failed: ", error);
    })

    console.log('userNotFound.length ', userNotFound.length)
    if(userNotFound.length > 0){
      var batch2 = db.batch();
      let userNameList = ""
      const dateTime = moment().format(defaultDateTimeformat)
      const dateTime_id = moment().format('DD_MMM_YYYY-HH:mm:ss')
      batch2.set(db.collection('userNotFoundGroup').doc(dateTime_id),{
        name: dateTime,
        dateTime: dateTime,
        amount: userNotFound.length,
        createDate: fb.firestore.FieldValue.serverTimestamp(),
        createUser : `import by ${user ? `${user.firstName} ${user.lastName}` : ''}`,
      })
      userNotFound.forEach(item => {
        userNameList += `\n${item.chapter} / ${item.firstName} ${item.lastName}`
        batch2.set(db.collection('userNotFound').doc(), {
          chapter: item.chapter,
          chapter_id: "1",
          firstName: item.firstName,
          lastName: item.lastName,
          userNotFoundGroup : dateTime,
          userNotFoundGroup_id : dateTime_id,
          createDate: fb.firestore.FieldValue.serverTimestamp(),
          createUser : `import by ${user ? `${user.firstName} ${user.lastName}` : ''}`,
          approveStatus_id: '2', approveStatus: 'Waiting'
        })
        batch2.set(db.collection('user').doc(), {
          // chapter: item.chapter,
          // chapter_id: "1",
          firstName: item.firstName,
          lastName: item.lastName,
          createDate: fb.firestore.FieldValue.serverTimestamp(),
          createUser : `import by ${user ? `${user.firstName} ${user.lastName}` : ''}`,
          status_id: '1', status: 'active',

          role: master.role.filter(p => p.label === "member")[0].label,
          role_id: master.role.filter(p => p.label === "member")[0].value,
          chapter: master.chapter.filter(p => p.label === "Integrity")[0].label,
          chapter_id: master.chapter.filter(p => p.label === "Integrity")[0].value,
          powerTeam: master.powerTeam.filter(p => p.label === "New Member")[0].label,
          powerTeam_id: master.powerTeam.filter(p => p.label === "New Member")[0].value,

          gs2021BusinessGoal: 0,
          gs2021BniGoal: 1000000,
          gs2021PayPerCustomer: 100000,
          gs2021ReferalChange: 50,
          gs2021TotalCustomer: 10,
          gs2021TotalRef: 20,
          gs2021TotalRefPerWeek: 20/50,
          gs2021Total121PerWeek: 20/50,
        })
      })
      // openNotificationWithIcon('warning','Can\'t update/merge user, because User Not Found',userNameList)
      // if(config && config.onlyActiveMember){
        batch2.commit().then(function () {
          console.log("batch.commit() user not found")
          // history.push(`/userList`)
        });
        openNotificationWithIcon('warning','Can\'t update/merge user, because User Not Found',
          <>
            {
              userNotFound.map(item => 
                <>
                  {item.chapter} / {item.firstName} / {item.lastName}<br />
                </>
              )
            }
          </>
        )
      // }
      
    }
    nextFunction()
  }), 2000);
  // document.location.href = '/#/userList';
  // window.location.href = '/#/userList'
}


export const numberFormat = new Intl.NumberFormat(
  {minimumFractionDigits: 2,
    maximumFractionDigits: 2,}
)
export const number2Digits = (number, digits) => parseFloat(number).toFixed(digits === undefined ? 2 : digits)

// numberFm('1234.567', 2, '$ ')
export const numberFm = (number, digits, prefix) => {
  let result = number2Digits(number || 0, digits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if(prefix)
    result = `${prefix}` + result 
  return result
  // return `${sign || '$'} ` + (Math.round(number * 100) / 100).toLocaleString()
}
/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
firestore
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const getDocById = (setDoc, setDocId, docName, docId) => {
  getDocByIdAndSubFunction(setDoc, setDocId, docName, docId, ()=>{})
}
export const getDocByIdAndSubFunction = async (setDoc, setDocId, docName, docId, subFunction) => {
  // console.log("docId = ", docId)
  const id = docId
  if(id === undefined || id === null){
    console.log("docId = ", id)
    return null;
  }
  // if(id === 'new'){
  //   db.collection(docName).add({createDate: fb.firestore.FieldValue.serverTimestamp()})
  //   .then(function(docRef) {
  //     id = docRef.id
  //     update()
  //     console.log("new ID: ", id);
  //   })
  //   .catch(function(error) {
  //     console.error("Error adding document: ", error);
  //   });
  // }else
  fatchData()
  // console.log("docName = ", docName)
  // console.log("docId = ", id)
  function fatchData() {
    return Promise.resolve( 
      db.collection(docName)
        .doc(id).get().then((doc) => {
          console.log(`${docName} docId = ${doc.id} | doc.data() = ` , doc.data());
          setDocId(doc.id);       
          setDoc(doc.data()); 
          subFunction(doc.data())
          return doc.data()
        })
    ).then(function func(value) {
      // console.log("Success1 = ",value); // "Success"
      return value;
    }).catch(function func(err) {
      console.log("err = ",err); 
    })
  }
}
export const getDocAndNext = async (setDoc, setDocId, docName, docId, whereList, subFunction) => {
  // console.log("docId = ", docId)
  const id = docId
  // if(id === undefined || id === null){
  //   console.log("docId = ", id)
  //   return null;
  // }
  fatchData()
  function fatchData() {
    let collection = db.collection(docName)
    if(id){
      console.log("iddd = ", id)
      collection = collection.doc(id)
    }
    whereList.forEach(param => {
      const {key, value} = param
      collection = collection.where(key, '==', value)
      // Object.entries(param).map(([key,value])=>{
      //   console.log(key, value)
      //   collection = collection.where(key, '==', value)
      //   return true
      // })
    })  
    return Promise.resolve( 
      collection.get().then((docList) => {
        console.log("doc = ", docList)
        console.log("length = ", docList.docs.length)
        if(docList.docs.length !== 1){
          console.log("result size = ", docList.docs.length)
        } else {
          const doc = docList.docs[0]
          console.log("result 1 = ", doc)
          setDocId(doc.id);       
          setDoc(doc.data()); 
          subFunction(doc.data())
          return doc.data();
        } 

        // setDocId(doc.id);       
        // setDoc(doc.data()); 
        // subFunction(doc.data())
        // return docList.data()
      })
    ).then(function func(value) {
      // console.log("Success1 = ",value); // "Success"
      return value;
    }).catch(function func(err) {
      console.log("err = ",err); 
    })
  }
}
// export const getDocById = (setDoc, setDocId, docName, docId, sub) => {
//   // console.log("docId = ", docId)
//   if(docId){
//     db.collection(docName)
//     .doc(docId).get().then((doc) => {
//       console.log(`${docName} docId = ${doc.id} `);
//       console.log(`${docName} doc.data() = ` , JSON.stringify(doc.data()));
//       setDocId(doc.id);       
//       setDoc(doc.data());     
//       sub(doc)
//     })
//   }
// }
export const getCol = (firebase, setTable, tableName) => {
  getColOrderBy(firebase,setTable,tableName,null,null)
}
export const getColOrderBy = (firebase, setTable, tableName, orderByColumn, orderByMode) => {
  if(tableName != null && tableName.length > 0){
    let collection = db.collection(tableName);
    if(orderByColumn != null)
      collection = collection.orderBy(orderByColumn, orderByMode)
      // collection = collection.orderBy("createDate", "desc")
    collection = collection.limit(defaultPageLimit)
    collection.onSnapshot(function func(data){
        console.log(`fetch ${tableName} size = `, data.docs.length);
        setTable(data.docs.map(doc => ({...doc.data(), id:doc.id})));
    });
  }
}
export const getColCondition = (setTable, tableName, whereList, orderList) => {
    if(tableName != null && tableName.length > 0){
    let collection = db.collection(tableName);
    whereList.forEach(param => {
      const {key, value} = param
      collection = collection.where(key, '==', value)
    })
    // orderList.forEach(param => {
    //   const {key, value} = param
    //   collection = collection.orderBy(key, value)
    // })
    // collection = collection.orderBy("createDate", "desc")
    collection = collection.limit(defaultPageLimit)
    console.log("tableName = ", whereList)
    collection.onSnapshot(function func(data){
        console.log(`fetch ${tableName} size = `, data.docs.length);
        setTable(data.docs.map(doc => ({...doc.data(), id:doc.id})));
    });
    }
}
export const getDoc = (view,  docParam, whereList, nextFunction) => {
  const { docName, doc, setDoc, docId, setDocId } = docParam
  console.log(`${docName} / ${docId} / search = `, whereList)
  const id = docId
  // if(id === undefined || id === null){
  //   console.log("docId = ", id)
  //   return null;
  // }
  // console.log("docName = ", docName)
  let collection = db.collection(docName)
  if(id && id !== undefined && id !== 'new'){
    // console.log("get doc with id = ", id)
    collection = collection.doc(id)
    collection.get().then((snapshort) => {
      // console.log('snapshort = ',snapshort)
      if(snapshort.data()){
        // console.log(`${docName} / ${snapshort.id} / data = `,snapshort.data())
        setDocId(snapshort.id);    
        const documentData = { id: snapshort.id, ...snapshort.data() }   
        setDoc(documentData)
        if(nextFunction)
          nextFunction(documentData)
        return documentData
      }
    })
    .then(function func(value) {
      // console.log("Success1 = ",value); // "Success"
      return value;
    }).catch(function func(err) {
      console.log("err = ",err); 
    })
  }
  else if(whereList && whereList.length > 0){
    whereList.forEach(param => {
      const {key, operation, value} = param
      console.log("whereList key = ", key)
      console.log("whereList operation = ", operation)
      console.log("whereList value = ", value)
      if(key && operation && value)
        collection = collection.where(key, operation, value)
    })
    collection.onSnapshot(function func(snapshot){
      const resultSize = snapshot.docs.length
      console.log(`${docName} size = `, resultSize)
      if(resultSize > 0){
        const resultList = snapshot.docs.map(doc1 => ({...doc1.data(), id:doc1.id}))
        const result = resultList[0]
        setDocId(result.id);       
        setDoc(result); 
        if(nextFunction)
          nextFunction(result)
      }
    });
  }else if (id === 'new')
    nextFunction()
  // else{
  //   console.log("docId are where are empty")
  //   // return null
  // }
}
export const getList = async (page, view,  docParam, whereList, orderList, nextFunction) => {
  const { docName, subDoc, docList, setDocList, async } = docParam
  const { pageSize, setSize, first, setFirst, last, setLast, action } = page
  // console.log(docName)
  // console.log("whereList = ", whereList)
  // console.log("orderList = ", orderList)
  // console.log("next = ", last.data())
  if(docName != null && docName.length > 0){
    let collection = db.collection(docName)
    console.log('subDoc', subDoc)
    if(subDoc){
      let subDocList = []
      if(Array.isArray(subDoc))
        subDocList = subDoc
      else
        subDocList = [subDoc]
      subDocList.forEach(item =>{
        // collection = collection.doc('YFOPl7FvqmKeWM9d5Wgw').collection('product')
        const { docId, collectionName } = item
        collection = collection.doc(docId).collection(collectionName)
      })
    }
    

    orderList.forEach(param => {
      const {orderBy, orderMode} = param
      // console.log("orderList orderBy = ", orderBy)
      // console.log("orderList orderMode = ", orderMode)
      // collection = collection.orderBy("createDate", "desc")
      if(orderBy && orderMode)
        collection = collection.orderBy(orderBy, orderMode)
    })
    if(whereList){
      let canSearchWithArray = true // Invalid query. You cannot use more than one 'array-contains' filter.
      whereList.forEach(param => {
      const {key, operation, value} = param
      // console.log("whereList key = ", key)
      // console.log("whereList operation = ", operation)
      // console.log("whereList value = ", value)
      if(key && operation && value)
        if( operation !== "array-contains" || (operation === "array-contains" && canSearchWithArray)){
          collection = collection.where(key, operation, value)
        }
        if( operation === "array-contains"){
          canSearchWithArray = false
          console.log("warn = limit search with array only one")
        }
      })
    }
    collection = collection.limit(pageSize || defaultPageLimit)
    if(action === "more" && last){
      collection = collection.startAfter(last)
    }

    const dataProcess = (snapshot) => {
      const resultSize = snapshot.docs.length
      // console.log(`(${async ? 'Async' : 'Sync'}) ${docName} / ${resultSize}`, { search: whereList, order: orderList})
      console.log(`${docName} (${resultSize}) ${async ? 'A' : 'S'}`, { search: whereList, order: orderList})
      if(!snapshot.empty) {
        let result = snapshot.docs.map(doc => ({...doc.data(), id:doc.id}))
        if(action === "more" && last){
          if(docList && docList[0]!==undefined)
            result = [...docList, ...result]
          setDocList(result)
        }else
          setDocList(result)
        const firstResult = snapshot.docs[0]
        const lastResult = snapshot.docs[resultSize - 1]
        setLast(resultSize === pageSize ? lastResult : null)
        // console.log("first= ", firstResult.data())
        // console.log("last = ", lastResult.data())
      }else{
        setLast(null)
        if(action === "search")
          setDocList([])
        // openNotificationWithIcon('info',`Export`, `No Data for Registration Form`)
      }
    }

    if(async === true){
      const ss = await collection.get();
      dataProcess(ss)
    }else{
      collection.onSnapshot(function func(ss){
        dataProcess(ss)
      })
    }
    
  }
}

// statsChangeAYMWD(docName, 
//   moment()
//   .set('year', 2021).set('month', 12-1).set('date', 2), 
// dataChange)
export const statsChangeAYMWD = (config, dateAction, dataChange) => {
  let tableName = ""
  let batch = ""
  
  if(typeof config === 'object' && config !== null){
    tableName = config.tableName
    batch = config.batch
  }else{
    tableName = config
    batch = db.batch()
  }
  const year  = padLeadingZeros(dateAction.get('y'), 4)
  const month = padLeadingZeros(dateAction.get('M')+1, 2)
  const date  = padLeadingZeros(dateAction.get('D'), 2)
  // const week  = padLeadingZeros(dateAction.get('w')-1, 2)
  const week  = padLeadingZeros(dateAction.get('w'), 2)

  console.log(`${year}/${month}/${date} w${week}`)
  // console.log(`${year}/${month}/${date} w${week}`, dataChange)
  // console.log(`year ${year} / month ${month} / date ${date} / week ${week}`, dataChange)

  
  batch.set(db.collection('_stat_').doc(tableName),dataChange, {merge: true})
  batch.set(db.collection('_stat_').doc(tableName).collection('year').doc(year),dataChange, {merge: true})
  batch.set(db.collection('_stat_').doc(tableName).collection('year').doc(year).collection('month').doc(month),dataChange, {merge: true})
  batch.set(db.collection('_stat_').doc(tableName).collection('year').doc(year).collection('month').doc(month).collection('date').doc(date),dataChange, {merge: true})
  
  batch.set(db.collection('_stat_').doc(tableName).collection('year').doc(year).collection('week').doc(week),dataChange, {merge: true})
  
  if(config.batch === undefined){
    batch.commit().then(function () {
      console.log("batch.commit() update statsChangeAYMWD")
    })
  }

}

// export const statsChangeAYMWDDemo365 = (docName, year) => {
//   for (let i = 0; i < 12; i+=1) {
//     for (let j = 0; j < 28; j+=1) {
//       const dataChange = { 
//         doc: fb.firestore.FieldValue.increment(1),
//         amount: fb.firestore.FieldValue.increment(Math.floor(Math.random() * 10) + 1),
//         amountK: fb.firestore.FieldValue.increment(Math.floor(Math.random() * 1000) + 1),
//         amountM: fb.firestore.FieldValue.increment(Math.floor(Math.random() * 1000000) + 1),
//       }
//       statsChangeAYMWD(docName, moment().set('year', year || 2021).set('month', i).set('date', j+1), dataChange)
//     }
//   }
// }

// statsChangeAYMWDDemoYear('demo', 2019, 2023)
export const statsChangeAYMWDDemoYear = (docName, yearFrom, yearTo) => {
  for (let y = yearFrom; y < yearTo+1; y+=1) {
    for (let i = 0; i < 12; i+=1) {
      const batch = db.batch()
      for (let j = 0; j < 28; j+=1) {
        const dataChange = { 
          doc: fb.firestore.FieldValue.increment(1),
          amount: fb.firestore.FieldValue.increment(Math.floor(Math.random() * 10) + 1),
          amountK: fb.firestore.FieldValue.increment(Math.floor(Math.random() * 1000) + 1),
          amountM: fb.firestore.FieldValue.increment(Math.floor(Math.random() * 1000000) + 1),
        }
        statsChangeAYMWD({tableName: docName, batch: batch }, moment().set('year', y || 2021).set('month', i).set('date', j+1), dataChange)
      }
      batch.commit().then(function () {
        console.log("batch.commit() update statsChangeAYMWDDemoYear")
      })
    }
  }
}



// export const getList = (page, view,  docParam, whereList, orderList, nextFunction) => {
//   const { docName, docList, setDocList } = docParam
//   const { pageSize, setSize, first, setFirst, last, setLast, action } = page
//   // console.log(docName)
//   // console.log("whereList = ", whereList)
//   // console.log("orderList = ", orderList)
//   // console.log("next = ", last.data())
//   if(docName != null && docName.length > 0){
//     let collection = db.collection(docName);
//     orderList.forEach(param => {
//       const {orderBy, orderMode} = param
//       // console.log("orderList orderBy = ", orderBy)
//       // console.log("orderList orderMode = ", orderMode)
//       // collection = collection.orderBy("createDate", "desc")
//       if(orderBy && orderMode)
//         collection = collection.orderBy(orderBy, orderMode)
//     })
//     if(whereList){
//       let canSearchWithArray = true // Invalid query. You cannot use more than one 'array-contains' filter.
//       whereList.forEach(param => {
//       const {key, operation, value} = param
//       // console.log("whereList key = ", key)
//       // console.log("whereList operation = ", operation)
//       // console.log("whereList value = ", value)
//       if(key && operation && value)
//         if( operation !== "array-contains" || (operation === "array-contains" && canSearchWithArray)){
//           collection = collection.where(key, operation, value)
//         }
//         if( operation === "array-contains"){
//           canSearchWithArray = false
//           console.log("warn = limit search with array only one")
//         }
//       })
//     }
//     collection = collection.limit(pageSize || defaultPageLimit)
//     if(action === "more" && last){
//       collection = collection.startAfter(last)
//     }
//     collection.onSnapshot(function func(snapshot){
//       const resultSize = snapshot.docs.length
//       // console.log(`${docName} / size = ${resultSize} / search = `, whereList)
//       console.log(`${docName} / ${resultSize}`, { search: whereList, order: orderList})
//       if(resultSize > 0){
//         let result = snapshot.docs.map(doc => ({...doc.data(), id:doc.id}))
//         if(action === "more" && last){
//           if(docList && docList[0]!==undefined)
//             result = [...docList, ...result]
//           setDocList(result)
//         }else
//           setDocList(result)
//         if(nextFunction)
//           nextFunction(result)
//         const firstResult = snapshot.docs[0]
//         const lastResult = snapshot.docs[resultSize - 1]
//         setLast(resultSize === pageSize ? lastResult : null)
//         // console.log("first= ", firstResult.data())
//         // console.log("last = ", lastResult.data())
//       }else{
//         setLast(null)
//         if(action === "search")
//           setDocList([])
//       }
//     });
//     // const snapshot = await  first.get();
    
//   }
// }



// sub query not work
// export const getList = (page, view,  docParam, whereList, orderList, nextFunction) => {
//   const { docName, docList, setDocList, subQuery } = docParam
//   const { pageSize, setSize, first, setFirst, last, setLast, action } = page
//   // console.log(docName)
//   // console.log("whereList = ", whereList)
//   // console.log("orderList = ", orderList)
//   // console.log("next = ", last.data())
//   if(docName != null && docName.length > 0){
//     let collection = db.collection(docName);
//     orderList.forEach(param => {
//       const {orderBy, orderMode} = param
//       // console.log("orderList orderBy = ", orderBy)
//       // console.log("orderList orderMode = ", orderMode)
//       // collection = collection.orderBy("createDate", "desc")
//       if(orderBy && orderMode)
//         collection = collection.orderBy(orderBy, orderMode)
//     })
//     if(whereList){
//       let canSearchWithArray = true // Invalid query. You cannot use more than one 'array-contains' filter.
//       whereList.forEach(param => {
//       const {key, operation, value} = param
//       // console.log("whereList key = ", key)
//       // console.log("whereList operation = ", operation)
//       // console.log("whereList value = ", value)
//       if(key && operation && value)
//         if( operation !== "array-contains" || (operation === "array-contains" && canSearchWithArray)){
//           collection = collection.where(key, operation, value)
//         }
//         if( operation === "array-contains"){
//           canSearchWithArray = false
//           console.log("warn = limit search with array only one")
//         }
//       })
//     }
//     collection = collection.limit(pageSize || defaultPageLimit)
//     if(action === "more" && last){
//       collection = collection.startAfter(last)
//     }
//     collection.onSnapshot(function func(snapshot){
//       const resultSize = snapshot.docs.length
//       // console.log(`${docName} / size = ${resultSize} / search = `, whereList)
//       console.log(`${docName} / ${resultSize}`, { search: whereList, order: orderList})
//       if(resultSize > 0){
//         // let result = snapshot.docs.map(doc => ({...doc.data(), id:doc.id}))
//         let result = snapshot.docs.map(
//           doc => {
//             const dd = { ...doc.data(), id:doc.id,}
//             let rr = dd
//             if(subQuery){
//               console.log('...subQuery',subQuery)
//               console.log('...dd.id',dd.id)
//               db.collection(subQuery.docName)
//               .where('weeks','==',40)
//               .where('user_id','==',dd.id)
//               .onSnapshot(snapshot2 => {
//                 const resultSize2 = snapshot2.docs.length
//                 console.log('...subQuery',resultSize2)
//                 if(resultSize2 > 0){
//                   const result2 = snapshot2.docs.map(doc2 => ({...doc2.data()}))
//                   if(result2.length > 0){
//                     console.log("result2[0]", result2[0])
//                     rr = {...rr, ...result2[0]}
//                   }
//                 }
//               })

//               // db.collection(subQuery.docName).doc(id).get().then((snapshort) => {
//               //   if(snapshort.data()){
//               //     // const documentData = { id: snapshort.id, ...snapshort.data() }  
//               //     rr = {...rr, ...snapshort.data()} 
//               //   }
//               // })
//               // .then(function func(value) {
//               //   return value;
//               // }).catch(function func(err) {
//               //   console.log("err = ",err); 
//               // })
//               console.log("rr", rr)
//             }
//             return rr
//           }
//         )
//         if(action === "more" && last){
//           if(docList && docList[0]!==undefined)
//             result = [...docList, ...result]
//           setDocList(result)
//         }else
//           setDocList(result)
//         if(nextFunction)
//           nextFunction(result, setDocList)
//         const firstResult = snapshot.docs[0]
//         const lastResult = snapshot.docs[resultSize - 1]
//         setLast(resultSize === pageSize ? lastResult : null)
//         // console.log("first= ", firstResult.data())
//         // console.log("last = ", lastResult.data())
//       }else{
//         setLast(null)
//         if(action === "search")
//           setDocList([])
//       }
//     });
//     // const snapshot = await  first.get();
    
//   }
// }
export const getListAsync = async (page, view,  docParam, whereList, orderList) => {
  const { docName, docList, setDocList } = docParam
  const { pageSize, setSize, first, setFirst, last, setLast, action } = page
  // console.log(docName)
  // console.log("whereList = ", whereList)
  // console.log("orderList = ", orderList)
  // console.log("next = ", last.data())
  
  if(docName != null && docName.length > 0){
    let collection = db.collection(docName);
    
    let canSearchWithDate = true
    let canSearchWithArray = true // Invalid query. You cannot use more than one 'array-contains' filter.
    if(whereList){
      whereList.forEach(param => {
      const {key, operation, value} = param
      // console.log("whereList key = ", key)
      // console.log("whereList operation = ", operation)
      // console.log("whereList value = ", value)
      if(key && operation && value)
        if( operation !== "array-contains" || (operation === "array-contains" && canSearchWithArray)){
          collection = collection.where(key, operation, value)
        }
        if(operation === "array-contains"){
          canSearchWithArray = false
          console.log('warn = limit search with only one Array', key)
        }
        if(key.indexOf('Date') >= 0){
          canSearchWithDate = false
          // console.log('warn = limit search with only one Date', key)
        }
      })
    }
    orderList.forEach(param => {
      const {orderBy, orderMode} = param
      // console.log("orderList orderBy = ", orderBy)
      // console.log("orderList orderMode = ", orderMode)
      // collection = collection.orderBy("createDate", "desc")
      if(orderBy.indexOf('Date') >= 0 && !canSearchWithDate)
        console.log('warn = limit search with only one Date', orderBy)
      else if(orderBy && orderMode)
        collection = collection.orderBy(orderBy, orderMode)
    })
    collection = collection.limit(pageSize || defaultPageLimit)
    if(action === "more" && last){
      collection = collection.startAfter(last)
    }

    const snapshot = await collection.get();
    const resultSize = snapshot.docs.length
    console.log(`(Async)${docName} / ${resultSize}`, { search: whereList, order: orderList})
    if(!snapshot.empty) {
      let result = snapshot.docs.map(doc => ({...doc.data(), id:doc.id}))
      if(action === "more" && last){
        if(docList && docList[0]!==undefined)
          result = [...docList, ...result]
        setDocList(result)
      }else
        setDocList(result)
      const firstResult = snapshot.docs[0]
      const lastResult = snapshot.docs[resultSize - 1]
      setLast(resultSize === pageSize ? lastResult : null)
      // console.log("first= ", firstResult.data())
      // console.log("last = ", lastResult.data())
    }else{
      setLast(null)
      if(action === "search")
        setDocList([])
      // openNotificationWithIcon('info',`Export`, `No Data for Registration Form`)
    }
    
    // await collection.onSnapshot((snapshot) => {
    //   if(resultSize > 0){
    //   }else{
    //   }
    // });

    // const snapshot = await  first.get();
  }
}

// master data
export const loadMaster = (firebase ,master, setMaster, tableName) => {
  return loadMasterDynamic(firebase ,master, setMaster, tableName, null, null)
}
export const loadMasterDynamic = (firebase ,master, setMaster, tableName, labelName, valueName) => {
  if(!valueName)
    valueName = 'name'
  db.collection(tableName)
  // .orderBy("name","desc")
  .orderBy(labelName,"asc")
  .limit(defaultPageLimit)
  .onSnapshot(function func(data){
    // console.log("mater data = ", data.docs);
    // const list= (data.docs.map(doc => ({...doc.data(), value:doc.id} )));
    // const list= (data.docs.map(doc => ({label:doc.data().name, value:doc.data().name} )));
    // const list= (data.docs.map(doc => ({label:doc.data().name, value:doc.id} )));

    // const list= (data.docs.map(doc => ({ label : doc.data()[labelName || 'name'], value : doc.data()[valueName || 'name'] } )));
    const list = (data.docs.map(doc => (
      { label : doc.data()[labelName || 'name']
      , value : valueName === "id" ? doc.id : doc.data()[valueName] } 
    )));
    // console.log("master = ", list)
    const masterTemp = master;
    masterTemp[tableName] = list;
    setMaster(masterTemp);
  });
//   return ;
}
// { tableName: "zActiveStatus", label: "name", value: "name", whereList: [], orderList: [], limit: 200},
export const loadMasterData = (firebase ,master, setMaster, masterQuery) => {
  const { tableName, label, value, whereList, orderList, limit } = masterQuery
  let valueName = value
  if(!valueName)
    valueName = 'name'
  db.collection(tableName)
  // .orderBy("name","desc")
  .orderBy(label,"asc")
  .limit(limit || defaultPageLimit)
  .onSnapshot(function func(data){
    const list = (data.docs.map(doc => (
      { label : doc.data()[label || 'name']
      , value : valueName === "id" ? doc.id : doc.data()[valueName] } 
    )));
    // console.log(`${tableName} = `, list)
    const masterTemp = master;
    masterTemp[tableName] = list;
    setMaster(masterTemp);
  });
//   return ;
}

export const doCacheSearch = (dispatch, values) => {
  dispatch({
    type: 'cache/SET_SEARCH',
    // payload: { docName: docName, docSearch: doc },
    payload: values,
  })
}

// <Col span={12}>
//   <Form.Item name="chapter" label="Chapter" rules={[ { required: false, }, ]}  >
//     <Select options={master.chapter} onChange={(v,e)=>{
//       changeSelect("chapter",v,e,
//         nextSelector(master, setMaster, 
//         [
//           { tableName: "user", label: ["firstName"," ","lastName"," (","nickName",")"], value: "id"
//           , whereList: [{key:"chapter_id",operation:"==",value:v}], orderList: [], limit: 2000},
//         ], eleOption, setEleOption, "userMember", v))
//       }} 
//       size="middle" placeholder="Select" allowClear showSearch filterOption={(input, option) => option.label.indexOf(input) >= 0} />
//   </Form.Item>
// </Col>
// { viewMode !== 'owner' && (
// <Col span={12}>
//   <Form.Item name="userMember" label="Member" rules={[ { required: false, }, ]}  >
//     <Select options={master.user} onChange={(v,e)=>{changeSelect("userMember",v,e)}} size="middle" placeholder="Select" allowClear showSearch filterOption={(input, option) => option.label.indexOf(input) >= 0} disabled={ eleOption && eleOption.userMember && eleOption.userMember.disabled === true} />
//   </Form.Item>
// </Col>
// )}

export const nextSelector = (master, setMaster, masterQuery, eleOption, setEleOption, nextSelectorName, id ) => {
  if(id){
    masterData(master, setMaster, masterQuery)
    setEleOption({...eleOption, [nextSelectorName] : { ...eleOption[nextSelectorName], disabled: false}})
  }else{
    setMaster({...master, user : []})
    setEleOption({...eleOption, [nextSelectorName] : { ...eleOption[nextSelectorName], disabled: true}})
  }
}

// masterData(master, setMaster, [
//   { tableName: "status", label: "name", value: "name", whereList: [], orderList: [], limit: 200},
//   { tableName: "training", label: "name", value: "id", whereList: [], orderList: [], limit: 200
//     , option:["price", "earlyBirdPrice"]},
//   { tableName: "chapter", label: "name", value: "id", whereList: [], orderList: [], limit: 200},
// { tableName: "chapter", label: "name", value: "id", whereList: [{key: "name",operation: "==",value: user.chapter}], orderList: [{orderBy: "none"}], limit: 200},
//   { tableName: "user", label: ["firstName"," ","lastName"," (","nickName",")"], value: "id", whereList: [], orderList: [], limit: 5000},
// ])

// { tableName: "training", label: "name", value: "id", 
//   whereList: [{key:"registerEndDate", operation:"<=", value: new Date()},], 
//   orderList: [{orderBy:"name", orderMode:"asc"}], limit: 200},




// <Form.Item name="productGroup" label="เลือกประเภท" rules={[ { required: false, }, ]}  >
//   <Select options={master.productGroup} onChange={(v,e)=>{
//     changeSelect("productGroup",v,e,
//       masterData(master, setMaster, [
//         { tableName: 'product', label: "name", value: "id", 
//           whereList: [{key: "productGroup_id",operation: "==",value: v}], 
//           orderList: [{orderBy:"name", orderMode:"asc"}], 
//           limit: 200,
//           option:["code","photo","unit"]},
//       ])
//     )}} 
//     size="middle" placeholder="Select" allowClear showSearch filterOption={(input, option) => option.label.indexOf(input) >= 0} />
// </Form.Item>
// <Form.Item name="product" label="เลือกสินค้า" rules={[ { required: false, }, ]}  >
//   <Select options={master.product} onChange={(v,e)=>{changeSelect("product",v,e)}} size="middle" placeholder="Select" allowClear showSearch filterOption={(input, option) => option.label.indexOf(input) >= 0} />
// </Form.Item>
// {
//   master && master.product && doc && doc.product && 
//     master.product.filter(f => f.value === doc.product_id).map(item =>
//       <div key={item.value}>
//         {
//           JSON.stringify(item.option.photo)
//         }
//       </div>
//     )
// }

export const masterData = (master, setMaster, masterQuery) => {
  let masterLog = []
  const excutedName = []
  masterQuery.forEach((m) => { 
    if(Object.keys(m).length > 0){
      const { tableName, label, value, whereList, orderList, limit, option, nextFunction } = m
      if(!excutedName.find(p => p === tableName)){
        excutedName.push(tableName)
        let lableName = label
        let valueName = value
        if(Array.isArray(label))
          lableName = label[0]
    
        if(!valueName)
          valueName = 'name'
        let collection = db.collection(tableName)
        whereList.forEach(param => {
          const {key, operation, value: value2} = param
          // console.log(`${key} ${operation} ${value2}`)
          if(key && operation && value2)
            collection = collection.where(key, operation || '==', value2)
        })
        // console.log("lableName = ", lableName)
        // console.log("orderList = ", orderList)
        if(orderList && orderList.length > 0)
          orderList.forEach(param => {
            const {orderBy, orderMode} = param
            if(orderBy !== 'none')
              collection = collection.orderBy(orderBy, orderMode)
            // collection = collection.orderBy("createDate", "desc")
          })
        else{
          collection = collection.orderBy(lableName,"asc")
        }
        // db.collection(tableName)
        // .orderBy("name","desc")
    
        collection = collection.limit(limit || defaultPageLimit)
        collection.onSnapshot(function func(snapshot){
          // const list = (data.docs.map(doc => (
          //   { label : doc.data()[lableName || 'name']
          //   , value : valueName === "id" ? doc.id : doc.data()[valueName] } 
          // )))
          const list = snapshot.docs.map(doc => {
            const docData = doc.data()
            let labelResult = ""
            // console.log("label = ",label)
            if(Array.isArray(label))
              for (let i = 0; i < label.length; i+=1) {
                const keyName = label[i];
                if(docData[keyName] !== undefined){
                  if(docData[keyName] && keyName.indexOf('Date') !== -1)
                    labelResult += secondsToDateString(docData[keyName]) 
                  else
                    labelResult += docData[keyName]
                }
                else
                  labelResult += keyName
                  // labelResult += ""
                // console.log("keyName = ",keyName)
                // console.log("labelResult = ",labelResult)
              }
            else
              labelResult = docData[lableName || 'name']
            // console.log("name all = ",labelResult)
            let optionParam = {}
            if(Array.isArray(option))
              for (let i = 0; i < option.length; i+=1) {
                const keyName = option[i];
                optionParam = {...optionParam, [keyName]: docData[keyName]}
                // console.log(keyName, docData[keyName])
              }
            return { label : labelResult, value : valueName === "id" ? doc.id : docData[valueName], option: {...optionParam} } 
          })
          // console.log(`${tableName} = `, list)
          // masterLog.push({name: tableName, value: list})
          masterLog = {...masterLog, [tableName]: list}
          const masterTemp = master
          // masterTemp[tableName] = list
          // setMaster(masterTemp)
          setMaster({...masterTemp ,...masterLog})
        })
      }else{
        console.log(`${tableName} duplicated masterData`)
      }
  
  
  
      // collection.onSnapshot(function func(snapshot){
      //   const resultSize = snapshot.docs.length
      //   console.log(`${docName} size = `, resultSize)
      //   if(resultSize > 0){
      //     let result = snapshot.docs.map(doc => ({...doc.data(), id:doc.id}))
      //     if(action === "more" && last){
      //       if(docList && docList[0]!==undefined)
      //         result = [...docList, ...result]
      //       setDocList(result)
      //     }else
      //       setDocList(result)
      //     const firstResult = snapshot.docs[0]
      //     const lastResult = snapshot.docs[resultSize - 1]
      //     setLast(resultSize === pageSize ? lastResult : null)
      //     // console.log("first= ", firstResult.data())
      //     // console.log("last = ", lastResult.data())
      //   }else{
      //     setLast(null)
      //     if(action === "search")
      //       setDocList([])
      //   }
      // })
    }



  })
  // console.log(`master = `, masterLog)
}

export const listData = (master, setMaster, masterQuery) => {
  let masterLog = []
  masterQuery.forEach((m) => { 
    // console.log("masterQuery = ", m)
    const { tableName, columnNameList, whereList, orderList, limit } = m
    let collection = db.collection(tableName)
    whereList.forEach(param => {
      const {key, operation, value: value2} = param
      console.log(`${key} ${operation} ${value2}`)
      collection = collection.where(key, operation || '==', value2)
    })
    if(orderList && orderList.length > 0)
      orderList.forEach(param => {
        const {orderBy, orderMode} = param
        collection = collection.orderBy(orderBy, orderMode)
        // collection = collection.orderBy("createDate", "desc")
      })
    
    collection.limit(limit || defaultPageLimit)
    .onSnapshot(function func(data){
      const list = data.docs.map(doc => {
      let result = {id : doc.id}
      if(columnNameList && columnNameList.length > 0)
        columnNameList.forEach(columnName => {
          result = {...result, [columnName]:doc.data()[columnName]}
        })
      return result 
    })
      // masterLog.push({name: tableName, value: list})
      // const masterTemp = master;
      // masterTemp[tableName] = list;
      // setMaster(masterTemp);

      masterLog = {...masterLog, [tableName]: list}
      const masterTemp = master
      setMaster({...masterTemp ,...masterLog})
    });
  })
  console.log(`listData = `, masterLog)
}
export const loadMasterUser = (firebase ,master, setMaster, whereList, orderList, limit) => {
  let result = []
  let m = []
   rdb
    .ref("users")
    // .orderByChild("status").equalTo('active')
    // .orderByChild('firstName','desc')
    .orderByChild('firstName')
    .on('value', snapshot => {
      console.log("fetch userMember size = ", snapshot.numChildren());
      snapshot.forEach(function func(childSnapshot) {
        const temp = childSnapshot.val()
        temp.id = childSnapshot.key
        if(temp.status !== 'deleted')
          result = [...result, temp]
          m = [...m, {label: `${temp.firstName} ${temp.lastName}`, value: temp.id} ]
      })  
      // console.log("result = ",m)
      const masterTemp = master;
      masterTemp.user = m;
      setMaster(masterTemp);
      // console.log("master = ",master)
    });
}

// {
//   title: 'Payment Status',
//   key: 'approveStatus',
//   render: ({id, approveStatus}) => 
//     badgeCondition(
//       { 
//         Approve: 'font-size-12 badge badge-success',
//         Waiting: 'font-size-12 badge badge-warning',
//         Decide : 'font-size-12 badge badge-danger',
//       },
//       approveStatus
//     )
//   // sorter: (a, b) => <>{ a.approveStatus && b.approveStatus ? a.approveStatus.length - b.approveStatus.length : ''}</>,
// },
export const badgeCondition = (condition, value) => 
  <span className={condition[value] || 'font-size-12 badge badge-default' }>
    {value}
  </span>

export const doSave = (docName, doc, docId, user, nextFunctionCreate, nextFunctionUpdate, mode)=>{ 
  // console.log("docName = ", docName)
  // console.log("doc = ", doc)
  // console.log("docId = ", docId)
  // console.log("user = ", user)
  // console.log("nextFunctionCreate = ", nextFunctionCreate)
  console.log(docId, doc)

  const userCreate = user ? {createUser_id: user.id, createUser: `${user.firstName} ${user.lastName}`, createDate: fb.firestore.FieldValue.serverTimestamp()} : {createDate: fb.firestore.FieldValue.serverTimestamp()}
  const userUpdate = user ? {updateUser_id: user.id, updateUser: `${user.firstName} ${user.lastName}`, updateDate: fb.firestore.FieldValue.serverTimestamp()} : {updateDate: fb.firestore.FieldValue.serverTimestamp()}
  // const userUpdate = user ? {updateUser_id: user.id, updateUser: `${user.firstName} ${user.lastName}`, createDate: fb.firestore.FieldValue.serverTimestamp()} : {createDate: fb.firestore.FieldValue.serverTimestamp()}
  
  if(mode === 'set'){
    db.collection(docName).doc(docId).set({...doc, ...userUpdate})
    .then(function func(){
      openNotificationWithIcon('success','Save Complete','' )
      if(nextFunctionUpdate)
        nextFunctionUpdate()
    })
    .catch(function func(err){
      console.log("err = ",err)
      openNotificationWithIcon('error','Save Error','Please try agian.' )
    })
  }
  else if(docId === 'new' || docId === undefined)
    db.collection(docName).add({...doc, ...userCreate, ...userUpdate, seq: doc.seq || 0 })
    .then(function func(result){
      console.log("result.id = ",result.id)
      openNotificationWithIcon('success','Save Complete','' )
      if(nextFunctionCreate)
        nextFunctionCreate(result)
    })
    .catch(function func(err){
      console.log("err = ",err)
      openNotificationWithIcon('error','Save Error','Please try agian.' )
    })
  else
    db.collection(docName).doc(docId).update({...doc, ...userUpdate})
    .then(function func(){
      openNotificationWithIcon('success','Save Complete','' )
      if(nextFunctionUpdate)
        nextFunctionUpdate()
    })
    .catch(function func(err){
      console.log("err = ",err)
      openNotificationWithIcon('error','Save Error','Please try agian.' )
    })
    
  
}
// Update

export const updateDocById = (firebase, docName, clearDoc, doc, docId) => {
  console.info("trying update document with id = ", docId);
  console.info(`docName =  `, JSON.stringify(doc));
  db.collection(docName)
  .doc(docId).set({...doc,updateDate: firebase.serverTimestamp()});
  clearDoc();
  console.log("save sucess");
}

/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
storage
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const deleteFile = (src) =>{
  console.log("file src = ",src)
  if(src){
    var ref = storage.ref().child(src);
    ref.delete().then(function func() {
      console.log("delete file success=", src)
      return true
    }).catch(function func(error) {
      console.error("error", error)
      return false
    });
  }
}
export const uploadImage = (firebase, doc, setDoc, path, image) => {
  uploadImageDynamic(firebase, doc, setDoc, "image", image, path)
};
export const uploadImageDynamic = (firebase, doc, setDoc, key, image, path) => {
  // const {storage} = firebase;
  if(image){
    // console.log("image = ", image)
    const newName = `${Math.floor(Math.random()*image.size)}_${image.name}`
    // console.log("newName = ", newName)
    
    if(image.size > uploadFileSizeLimit){
      openNotificationWithIcon('error','File Size Limit',`Please try agin upload file size not over ${uploadFileSizeLimit/1000} KB === ${image.size}` )
      return false
    }
    const uploadTask = storage.ref(`${path}/${newName}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      error => {
        // Error function ...
        console.log(error);
      },
      () => {
      // complete function ...
      storage
        .ref(path)
        .child(newName)
        .getDownloadURL()
        .then(url => {
            console.log("path ",path);
            console.log("newName = ",newName);
            console.log("uploaded url = ",url);
            setDoc({ ...doc, [key]: url, [`${key}_src`]: `${path}/${newName}` });
            return true
        });
      }
    );
  }
};

/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
ETC
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const noImage = "../appDefault/600x600.webp";

export const getIdFromPath = (point) => {
  const words = window.location.href.split('/');
  let param = ""
  if(words.length === 5){
    const words1 = words[point].split("#")[0];
    param = words1.split("/")[0];
    console.log("getIdFromPath  = ",param);
  }
  return param;
}

export const JsonPretty = (p) =>{
  return <JSONPretty style={{textAlign:"left"}} data={p.data} />;
}
// var local = "th-TH";
// export default function Project() {
// }
export const dateFormat = (date) => {
  // console.log(date);
  if(date != null){
    // return new Date(date.toDate()).toLocaleDateString("en-US");
    return new Intl.DateTimeFormat("th-TH", {
      year: "numeric", month: "long", day: "2-digit",
    }).format(date.toDate());
  }
  return date
}
export const dateValue = (date) => {
  if(date != null && !(date instanceof Date)){
    console.log("date ===== ", JSON.stringify(date) );
    return new Intl.DateTimeFormat("en-US", {
    year: "numeric", month: "2-digit", day: "2-digit",
    }).format(date.toDate());
  } 
  return date;
}
function toDateTime(secs) {
  const t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}
export const formatDate = (date) => {
  const date1 = date.seconds != null
   ? new Date(date) // Already a javascript date object
   : date;
   return date1;
}

/** * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
changeDocValue changeElement
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export const addWhereList = (doc, whereList, operation, key) =>{
  // console.log(`last of ${key} = `, key.lastIndexOf("_keywords")!==-1)
  // const keyName = `${key}${operation === 'array-contains' && key.lastIndexOf("_keywords")!==-1 ? `${key}_keywords`:''}`
  const keyName = key
  // console.log("keyName = ", keyName)
  // console.log("doc[key] = ", doc[key])
  
  // if(doc[key] && doc[key].length > 0)
  //   whereList = [...whereList, {key:keyName, operation: operation, value: doc[key][0]}]

  // if(doc[key] && Array.isArray(doc[key]) && doc[key].length > 0)
  //   // whereList = [...whereList, {key:keyName, operation: operation, value: doc[key][0]}]
  //   whereList = [...whereList, {key:keyName, operation: operation, value: doc[key][0]}]
  // else 



  // if(doc[key] && doc[key].length > 0)
  //   whereList = [...whereList, {key:keyName, operation: operation, value: doc[key]}]
  // else
  //   whereList = [...whereList, {key:keyName, operation: null, value: null}]

  if(doc[key] && doc[key].length > 0  && key === 'roles')
    whereList = [...whereList, {key: operation === "array-contains" ? `${keyName}_id` : keyName, operation: operation, value: doc[key]}]
  else if(doc[key] && doc[key].length > 0 && operation === "array-contains")
    whereList = [...whereList, {key: `${keyName}_keywords`, operation: operation, value: doc[key].toLowerCase()}]
  else if(doc[key] && doc[key].length > 0)
    whereList = [...whereList, {key: keyName, operation: operation, value: doc[key]}]
  else if(doc[key] && typeof doc[key] === "boolean")
    whereList = [...whereList, {key: keyName, operation: operation, value: doc[key]}]
  else
    whereList = [...whereList, {key: keyName, operation: null, value: null}]
  // console.log('addWhereList ', whereList)
  return whereList
}
export const defaultWhereList = (doc, whereList) =>{
  if(doc){
    // if(doc.createUser)
    //   addWhereList(doc, whereList, "array-contains", "createUser")
    if(doc.status)
      whereList = [...whereList, {key:"status", operation:"==", value:doc.status}]
    if(doc.createDateFrom)
      whereList = [...whereList, {key:"createDate", operation:">=", value:doc.createDateFrom}]
    if(doc.createDateTo)
      whereList = [...whereList, {key:"createDate", operation:"<=", value:doc.createDateTo}]
    if(doc.createDateRange){
      whereList = [...whereList, {key:"createDate", operation:">=", value:doc.createDateRange[0]}]
      whereList = [...whereList, {key:"createDate", operation:"<=", value:doc.createDateRange[1]}]
    }
  }
  return whereList
}
export const addOrderList = (doc, orderList, orderBy, orderMode) =>{
  if(doc[orderBy])
    orderList = [...orderList, {orderBy: orderBy, orderMode: orderMode}]
  return orderList
}
      // if(doc.createDateFrom)
      //   whereList = [...whereList, {key:"createDate", operation:">=", value:doc.createDateFrom}]
      // if(doc.createDateTo)
      //   whereList = [...whereList, {key:"createDate", operation:"<=", value:doc.createDateTo}]
      // if(doc.createDateRange){
      //   whereList = [...whereList, {key:"createDate", operation:">=", value:doc.createDateRange[0]}]
      //   whereList = [...whereList, {key:"createDate", operation:"<=", value:doc.createDateRange[1]}]
      // }
export const defaultOrderList = [{orderBy: "createDate", orderMode: "desc"}]

// hangeState({expand: !state.expand},"sub1.sub2.sub3.sub4")
export const doChangeState = (state, setState, value, subObject) => {
  let temp = { ...state }
  let sub1 = null
  let sub2 = null
  let sub3 = null
  let sub4 = null
  const sub = subObject && subObject.indexOf('.') !== -1 ? subObject.split(".") : [subObject]
  if(sub.length >= 1)
    sub1 = sub[0]
    if(sub.length >= 2)
      sub2 = sub[1]
      if(sub.length >= 3)
        sub3 = sub[2]
        if(sub.length >= 4)
          sub4 = sub[3]
  if(sub1){
    temp = temp || {}
    if(sub2){
      temp[sub1] = temp[sub1] || {}
      if(sub3){
        temp[sub1][sub2] = temp[sub1][sub2] || {}
        if(sub4){
          temp[sub1][sub2][sub3] = temp[sub1][sub2][sub3] || {}
          temp[sub1][sub2][sub3][sub4] = { ...temp[sub1][sub2][sub3][sub4], ...value }
        }else{
          temp[sub1] = temp[sub1] || {}
          temp[sub1][sub2][sub3] = { ...temp[sub1][sub2][sub3], ...value }
        }
      }else{
        temp[sub1][sub2] = { ...temp[sub1][sub2], ...value }
      }
    }else
      temp[sub1] = { ...temp[sub1], ...value }
      setState(temp)
  } else
    setState({ ...state, ...value })
  console.log(state)
}
// export const doChangeState = (state, setState, value, sub1, sub2, sub3, sub4) => {
//   const temp = { ...state }
//   if(sub1){
//     if(sub2){
//       temp[sub1] = temp[sub1] || {}
//       if(sub3){
//         temp[sub1][sub2] = temp[sub1][sub2] || {}
//         if(sub4){
//           temp[sub1][sub2][sub3] = temp[sub1][sub2][sub3] || {}
//           temp[sub1][sub2][sub3][sub4] = { ...temp[sub1][sub2][sub3][sub4], ...value }
//         }else{
//           temp[sub1] = temp[sub1] || {}
//           temp[sub1][sub2][sub3] = { ...temp[sub1][sub2][sub3], ...value }
//         }
//       }else{
//         temp[sub1][sub2] = { ...temp[sub1][sub2], ...value }
//       }
//     }else
//       temp[sub1] = { ...temp[sub1], ...value }
//       setState(temp)
//   } else
//     setState({ ...state, ...value })
//   console.log(state)
// }

const createKeywords = messages => {
  const result = []
  const words = messages.toLowerCase().split(' ')
  // console.log('words = ', words)
  words.forEach(word => {
    let curName = ''
    // console.log('word = ', word)
    word.split('').forEach(letter => {
      // console.log('letter = ', letter)
      curName += letter
      result.push(curName)
    })
  })
  // console.log('createKeywords = ',result)
  return result;
}
// const createKeywords = name => {
//   const result = [];
//   let curName = '';
//   console.log('words = ', name)
//   name.split('').forEach(letter => {
//     console.log('letter = ', letter)
//     curName += letter;
//     result.push(curName);
//   });
//   console.log('createKeywords = ',result)
//   return result;
// }
export const doChangeTextKeywords = (doc, setDoc, event) => { 
  setDoc({ ...doc, [event.target.id]: event.target.value, [`${event.target.id}_keywords`]: createKeywords(event.target.value) }) 
}
// const generateKeywords = names => {
//   const [first, middle, last, sfx] = names;
//   const suffix = sfx.length > 0 ? ` ${sfx}.` : '';
//   const keywordNameWidthoutMiddleName = createKeywords(`${first} ${last}${suffix}`);
//   const keywordFullName = createKeywords(`${first} ${middle} ${last}${suffix}`);
//   const keywordLastNameFirst = createKeywords(`${last}, ${first} ${middle}${suffix}`);
  
//   const middleInitial = middle.length > 0 ? ` ${middle[0]}.` : '';
//   const keywordFullNameMiddleInitial = createKeywords(`${first}${middleInitial} ${last}${suffix}`);
//   const keywordLastNameFirstMiddleInitial = createKeywords(`${last}, ${first}${middleInitial}${suffix}`);
//   return [
//     ...new Set([
//       '',
//       ...keywordFullName,
//       ...keywordLastNameFirst,
//       ...keywordFullNameMiddleInitial,
//       ...keywordLastNameFirstMiddleInitial,
//       ...keywordNameWidthoutMiddleName
//     ])
//   ];
// }
export const createKeywordsList = names => {
  let result = []
  if(Array.isArray(names) && names.length){
    let wordList = []
    names.forEach(name => {
      wordList = [...wordList, ...createKeywords(name)]
      // result.push(createKeywords(name))
    })
    const merged = names.join(' ').toLowerCase()
    wordList = [...wordList, merged]
    // console.log('wordList = ', wordList)
    result = Object.assign(result, wordList)
    // result.push(createKeywords(`${names.join(' ')}`))
  }
  console.log("createKeywordsList result = ", result)
  return result
}
export const doChangeTextKeywordsList = (doc, setDoc, key, values) => { 
  setDoc({ ...doc, [key]: createKeywordsList(values) }) 
}

export const doChangeWithUserActionBy = (doc, setDoc, user, key, value) => { 
  const action = user ? {actionUser_id: user.id, actionUser: `${user.firstName} ${user.lastName}`, actionDate: fb.firestore.FieldValue.serverTimestamp()} : {actionDate: fb.firestore.FieldValue.serverTimestamp()}
  setDoc({
    ...doc,
    [key]: value || null,
    [`${key}User`]: action.actionUser || null,
    [`${key}User_id`]: action.actionUser_id || null,
    [`${key}Date`]: action.actionDate || null,
  })
}
export const doChangeKeyValue = (doc, setDoc, key, value) => { setDoc({...doc, [key]: value }) }
export const doChangeText = (doc, setDoc, event) => { setDoc({...doc, [event.target.id]: event.target.value}) }
export const doChangeValue = (doc, setDoc, key, value, index) => { setDoc({...doc,[key]:value}) }

export const doChangeCheckBox = (doc, setDoc, event) => { setDoc({...doc, [event.target.id]: event.target.checked}) }
export const doChangeRadio = (doc, setDoc, key, label, value) => { setDoc({...doc, [key]: label, [`${key}_id`]: value}) }
export const doChangeSelect = (doc, setDoc, key, value, event, nextFunction) => { 
  // setDoc({...doc,[key]: event ? event.label : null, [`${key}_id`]: event ? event.value : null }) 
  if(key && Array.isArray(value)){
    console.log(key)  
    console.log(value)
    console.log(event.map(p => p.label))
    console.log(event.map(p => p.value))
    // console.log(event.map(p => p.label))
    // setDoc({...doc,[key]: event ? event.map(p => p.label) : null, [`${key}_id`]: event ? event.map(p => p.value) : null }) 
    setDoc({...doc,[key]: event ? event.map(p => p.label) : null, [`${key}_id`]: event ? event.map(p => p.value) : null }) 
  }
  else
    setDoc({...doc,[key]: event ? event.label : null, [`${key}_id`]: event ? event.value : null }) 
  if(nextFunction)
    nextFunction()
}
export const doChangeSelectMulti = (doc, setDoc, key, value, event) => { 
  // setDoc({...doc,[key]: event ? event.label : null, [`${key}_id`]: event ? event.value : null }) 
  if(Array.isArray(value)){
    // console.log(value)
    // console.log(event.map(p => p.label))
    setDoc({...doc,[key]: event ? event.map(p => p.label) : null, [`${key}_id`]: event ? event.map(p => p.value) : null }) 
  }
  else
    setDoc({...doc,[key]: event ? event.label : null, [`${key}_id`]: event ? event.value : null }) 
}
// const changeDate = (key, type, date) => { 
//   const nextFunction = (document) => {
//     console.log("nextFunction")
//   }
//   doChangeDate(doc, setDoc, key, type, date, nextFunction) 
// }
export const doChangeDate = (doc, setDoc, key, type, date, nextFunction)=>{
  // console.log("date = ", date)
  if(date){
    if(type === "datetime")
      setDoc({...doc, [key]: date ? date.toDate() : null })
    if(type === "Date") // new Date(), Date in system
      setDoc({...doc, [key]: date ? date : null })
    if(type === "date")
      setDoc({...doc, [key]: date ? date.set({"hour": 0, "minute": 0, "second": 0}).toDate() : null })
    if(type === "time")
      setDoc({...doc, [key]: date ? date.toDate() : null })
    if(type === "from")
      setDoc({...doc, [key]: date ? date.set({"hour": 0, "minute": 0, "second": 0}).toDate() : null })
    if(type === "to")
      setDoc({...doc, [key]: date ? date.set({"hour": 23, "minute": 59, "second": 59}).toDate() : null })
    if(type === "range")
      setDoc({...doc, [key]: [
        date[0].set({"hour": 0, "minute": 0, "second": 0}).toDate()
      , date[1].set({"hour": 23, "minute": 59, "second": 59}).toDate()
      ]})
  }else{
    setDoc({...doc, [key]: null })
  }
  // console.log("doc = ", doc)
  if(nextFunction)
    nextFunction()
}
export const doChangeFile = (doc, setDoc, path, key, image, fileSizeLimit, option) => {
  let image_base64 = null
  let image_base64_compress = null
  // console.log('option = ', option)
  if(option){
    const compress = option.compress
    const compressOption = option.compressOption || {
      width : 600,
      height : 600,
      format : "WEBP",
      quality : 70,
      rotation : 0,
    }
    console.log('image = ',image)
    if(image.size > 2048000 ){
      compressOption.width = 800
      compressOption.height = 800
    }
    if(compress){
      Compress.imageFileResizer(
        image, // the file from input
        compressOption.width || 600, // width
        compressOption.height || 600, // height
        compressOption.format || "WEBP", // compress format WEBP, JPEG, PNG
        compressOption.quality || 70, // quality
        compressOption.rotation || 0, // rotation
        (uri) => {
          image_base64 = uri
          image_base64_compress = compressOption
          console.log(`${key}_base64 uri = `, uri);
          console.log(`${key}_base64 length = ${image_base64.length} bytes`, );
          // console.log(`${key}_base64 length = ${Buffer.from(image_base64).length} bytes`, );
          // You upload logic goes here
        },
        "base64" // blob or base64 default base64
      )
    }
  }
  if(image){
    const newName = `${Math.floor(Math.random()*image.size)}_${image.name}`
    const limit = fileSizeLimit || uploadFileSizeLimit;
    if(image.size > limit) {
      openNotificationWithIcon('error', 'File Too Large', `Please upload file size not over ${limit/1000} KB ` )
      console.log("err file size limit = ", image.size)
      return false
    }
    const uploadTask = storage.ref(`${path}/${newName}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break;
          case 'running':
            console.log('Upload is running')
            break;
          default:
            console.log('state = ',snapshot.state)
            break;
        }
      },
      error => {
        // Error function ...
        console.log(error);
      },
      () => {
        storage
          .ref(path || 'none_path')
          .child(newName)
          .getDownloadURL()
          .then(url => {
            if(doc && doc[`${key}_src`])
              deleteFile(doc[`${key}_src`])
            console.log("uploaded url = ", url);
            setDoc({ ...doc, 
              [key]: url, 
              [`${key}_src`]: `${path}/${newName}`,
              [`${key}_name`]: image.name,
              [`${key}_size`]: image.size,
              [`${key}_type`]: image.type,
              [`${key}_upload`]: true,
              [`${key}_base64`]: image_base64,
              [`${key}_base64_size`]: image_base64 && Buffer.from(image_base64).length,
              [`${key}_base64_compress`]: image_base64_compress,
              
            });
            return true
          })
          .catch(err => {
            console.log("err = ", err);
            openNotificationWithIcon('error','File Upload Fail',`Please try again` )
          })
      }
    );
  }
};

export const doChangeFileDynamic = (inputList, setInputList, image, path, key, index, fileSizeLimit) => {
  if(image){
    const newName = `${Math.floor(Math.random()*image.size)}_${image.name}`
    const limit = fileSizeLimit || uploadFileSizeLimit;
    if(image.size > limit) {
      openNotificationWithIcon('error','File Too Large',`Please upload file size not over ${limit/1000} KB ` )
      console.log("err file size limit = ", image.size)
      return false
    }
    const uploadTask = storage.ref(`${path}/${newName}`).put(image);
    uploadTask.on(
      "state_changed",
      snapshot => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      error => {
        // Error function ...
        console.log(error);
      },
      () => {
        storage
          .ref(path)
          .child(newName)
          .getDownloadURL()
          .then(url => {
            // if(doc && doc[`${key}_${index}_src`])
            //   deleteFile(doc[`${key}_${index}_src`])
            // console.log("uploaded url = ", url);
            // setDoc({ ...doc, [key]: url, [`${key}_src`]: `${path}/${newName}` });

            if(index){
              // inputList[index]={}
              inputList[index].value = url;  
              // inputList[index] = { element: "image", [key]: url };  
              // setInputList([...inputList, { element: "image", [key]: url }]);
            }
            else
              setInputList([...inputList, { element: "image", [key]: url }]);
            return true
          })
          .catch(err => {
            console.log("err = ", err);
            openNotificationWithIcon('error','File Upload Fail',`Please try again` )
          })
      }
    );
  }
};



export const toEditState = (html) => {
  // if(html !== '<p></p>'){
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorStateResult = EditorState.createWithContent(contentState);
    return editorStateResult
  // }
  // else
  //   return EditorState.createEmpty()
}
export const createMarkup = (html) => {
  if(html)
    return  {
      __html: DOMPurify.sanitize(html)
    }
}

// const [editorState, setEditorState] = React.useState();
export const doChangeEditor = (doc, setDoc, editorState, setEditorState, key, value, index) => { 
  if(value){
    setEditorState({...editorState, [key]: value})
    setDoc({...doc, [key]: draftToHtml(convertToRaw(value.getCurrentContent()))})
    // setDoc({...doc, [key]: value}) 
  }
}



export const doDelete = (docName, id, nextFunction)=>{
  if(!docName){
    console.log("err null docName = ", docName)
    return false
  }
  if(!id){
    console.log("err null id = ", id)
    return false
  }
  db.collection(docName).doc(id).get().then(function func(document){
    if(nextFunction)
      nextFunction(document)
  }).then(function func(){
    db.collection(docName).doc(id).delete() 
    openNotificationWithIcon('info','Deleted Complete','' )
  }).catch(function func(err){
    console.error("err = ",err)
    openNotificationWithIcon('error','Deleted Fail',', Please Try Agian.' )
  })
}
export const doDuplicate = (docName, id, user, nextFunction)=>{
  if(!docName){
    console.log("err null docName = ", docName)
    return false
  }
  if(!id){
    console.log("err null id = ", id)
    return false
  }
  let source = null
  db.collection(docName).doc(id).get().then(function func(document){
    source = document.data()
    if(nextFunction)
      nextFunction(document)
  }).then(function func(){
    const userCreate = user ? {createUser_id: user.id, createUser: `${user.firstName} ${user.lastName}`, createDate: fb.firestore.FieldValue.serverTimestamp()} : {createDate: fb.firestore.FieldValue.serverTimestamp()}
    const userUpdate = user ? {updateUser_id: user.id, updateUser: `${user.firstName} ${user.lastName}`, updateDate: fb.firestore.FieldValue.serverTimestamp()} : {updateDate: fb.firestore.FieldValue.serverTimestamp()}
    db.collection(docName).add({...source, ...userCreate, ...userUpdate})
    .then(function func2(result){
      console.log("result.id = ",result.id)
      openNotificationWithIcon('success','Dupplicate item Complete','' )
      // if(nextFunctionCreate)
      //   nextFunctionCreate(result)
    })
    .catch(function func2(err){
      console.log("err = ",err)
      openNotificationWithIcon('error','Dupplicate item Error','Please try agian.' )
    })
  }).catch(function func(err){
    console.error("err = ",err)
    openNotificationWithIcon('error','Get Source Fail',', Please Try Agian.' )
  })
}

export const doChangeRowSaveText = (docName, id, event, nextFunction) => { 
  const key = event.target.name
  const value = event.target.value
  const result = {[key]: value}
  db.collection(docName).doc(id).set(result, {merge: true}).then(function func(){ console.log(`updated ${key} = `, value)})
  if(nextFunction)
    nextFunction()
}
export const doChangeRowSaveSelect = (docName, id, key, value, event, nextFunction) => { 
  // setDoc({...doc,[key]: event ? event.label : null, [`${key}_id`]: event ? event.value : null }) 
  let result = {}
  if(key && Array.isArray(value)){
    console.log(key)  
    console.log(value)
    console.log(event.map(p => p.label))
    console.log(event.map(p => p.value))
    // console.log(event.map(p => p.label))
    // setDoc({...doc,[key]: event ? event.map(p => p.label) : null, [`${key}_id`]: event ? event.map(p => p.value) : null }) 
    result = {[key]: event ? event.map(p => p.label) : null, [`${key}_id`]: event ? event.map(p => p.value) : null }
  }
  else
    result = {[key]: event ? event.label : null, [`${key}_id`]: event ? event.value : null }
  if(result !== {})
    db.collection(docName).doc(id).set(result, {merge: true}).then(function func(){ console.log(`updated ${key} = `, value)})
  if(nextFunction)
    nextFunction()
}

// ----------------------------------------------------------------

// #### training stats registered amount by chapter
// statsChange(
//   'training',
//   data.training_id, 
//   [
//     { key: 'chapter', value: data.chapter, id: data.chapter_id }
//   ],
//   [
//     { key: 'statsMemberRegistered', value: 1 }, 
//   ],
//   [
//     { key: 'userSTChapter', value: `${user.firstName || ''} ${user.lastName || ''}` }, 
//     { key: 'userSTChapter_id', value: user.id || '' },
//   ])

// #### chapter stats user amount by chapter
// statsChange('chapter', doc.chapter_id, [
//   { key: 'chapter', value: doc.chapter, id: doc.chapter_id },
// ]
// ,[
//   { key: 'user', value: user.length }, 
// ]
// ,[])

// #### campaign stats reach amount by influencer
// statsChange('campaign', doc.campaign_id, [
//   { key: 'influencer', value: doc.influencer, id: doc.influencer_id },
// ]
// ,[
//   { key: 'reach', value: reach }, 
//   { key: `View (${doc.contentType})`, value: docId === 'new' ? doc.view : doc.view }, 
// ]
// ,[])

// #### campaign stats content amount by contentType
// statsChange('campaign', doc.campaign_id, [
//   { key: 'contentType', value: doc.contentType, id: doc.contentType_id },
// ]
// ,[
//   { key: 'content', value: content }, 
//   { key: 'viewByContentType', value: view },
// ]
// ,[])

// individaul stats
// statsChange('_stat_', 'zone', 
// [{ key: 'zone', value: data.zone, id: data.zone_id },],
// [
//   { key: 'view', value: 1 }, 
// ]
// ,[])
// statsChange('_stat_', 'shop', 
// [{ key: 'shop', value: data.name, id: data.id }],
// [
//   { key: 'view', value: 1 }, 
// ]
// ,[
//   { key: 'code', value: data.code },
// ])


export const statsChange = (docName, docId, keyList, changeValueList, otherValueList) => { 
  let ids = ''
  let keys = ''
  let values = ''
  const options = {}
  keyList.forEach(param => {
    const {id, key, value, updateAllStats} = param
    // console.log("whereList id = ", id)
    // console.log("whereList key = ", key)
    // console.log("whereList value = ", value)
    ids = `${ids !== '' ? `${ids}_` : `${ids}` }${id}`
    keys = `${keys !== '' ? `${keys}_` : `${keys}` }${key}`
    values = `${values !== '' ? `${values} | ` : `${values}` }${value}`
    options.updateAllStats = updateAllStats || true
  })
  let changeValue = {}
  if(changeValueList && changeValueList.length)
    changeValueList.forEach(param => {
      const {key, value} = param
      changeValue = {...changeValue, [key]:fb.firestore.FieldValue.increment(parseInt(value,10))}
      // changeValue = {...changeValue, [key]:parseInt(value,10)} // init data
    })
  let otherValue = {}
  if(otherValueList && otherValueList.length)
    otherValueList.forEach(param => {
      const {key, value} = param
      otherValue = {...otherValue, [key]:value}
    })
  console.log('docName = ', docName)
  console.log('docId = ', docId)
  const docStats = db.collection(docName).doc(docId)
  return db.runTransaction((trx) => {
    return trx.get(docStats).then((documentName) => {
      trx.set(docStats, { 
        _stats_ : { 
          [`_all_`]: {...changeValue},
          [keys] : {
            [`_all_${keys}`]: options.updateAllStats ? {...changeValue} : {},
            [ids] : {
              name : values,
              ...changeValue, 
              ...otherValue, 
              createDate: fb.firestore.FieldValue.serverTimestamp(),
              updateDate: fb.firestore.FieldValue.serverTimestamp(),
            }
          }
        },
      }, { merge: true}); 
    });
  })
  .then(() => {
    console.log(`${docName} Transaction successfully!`)
  })
  .catch((error) => console.log("Transaction failed: ", error));
}
// QT000001
  // const docPrefix = {prefixName: "QT", numberDigits: 6}
  // QT2021020001
  // const docPrefix = {prefixName: "QT", yearDigits: 4, numberDigits: 6}
  // QT2021020001
  const docPrefixDefault = { 
    yearDigits: 4, monthDigits: 2, dayDigits: 2, numberDigits: 4, 
    dilimiter: '',
    // dilimiter: "-",
    // machineValue: 22 , machineDigits: 2, employeeValue: 333, employeeDigits: 4 
  }
  const createDocRun = () => {
    const date = new Date("2022-03-10")
    docNoChangeFull('order', '1', 'QT', 1, docPrefixDefault, date)
  }
  const deleteDocRun = () => {
    const date = new Date("2022-03-10")
    docNoChangeFull('order', '1', 'QT', -1, docPrefixDefault, date)
  }

  // docNoControl('add', docName, docId, 'docNoName', 'IV', new Date(), 1) 
  export const docNoControl = (action, docName, docId, docNoName, docPrefix, date, amount) => {
    // if(action === 'get'){
    //   getDocNo(docName)
    // }else 
    if(action === 'add'){
      // docNoChange('invoice', '1', docPrefix, 1, new Date())
      docNoChange(docName, docId, docPrefix, 1, new Date())
    }else if(action === 'delete'){
      // docNoChange('invoice', '1', docPrefix, -1, new Date())
      docNoChange(docName, docId, docPrefix, -1, new Date())
    }
  }

  // const getDocNo = (docName) => { 
  //   const _docNo_ = db.collection("_docNo_").doc(docName);
  //   _docNo_.get().then((snapshort) => {
  //     console.log(`${docName} docId = ${snapshort.id} | doc.data() = ` , snapshort.data());
  //     setDocNo(snapshort && snapshort.data() && snapshort.data().docNo || 'Empty'); 
  //   })

  //   db.collection("_docNo_").onSnapshot(snap => {
  //     console.log('docs = ', snap.docs.length);
  //     const data = snap.docs.map(doc1 => ({...doc1.data(), id:doc1.id}))
  //     console.log('data = ', data);
  //   })
  // }

  // const date = new Date("2022-03-10")

  // const docNoIncrement = (docName, id, prefix, date) => docNoCreate(docName, id, prefix, docPrefixDefault, date, 1)
  // const docNoDecrement = (docName, id, prefix, date) => docNoCreate(docName, id, prefix, docPrefixDefault, date, -1)

  export const docNoChange = (docName, docId, docPrefix, changeValue, date) => { 
    docNoChangeFull(docName, docId, docPrefix, changeValue, docPrefixDefault, date)
  }

  // docNoChangeFull('invoice', '11111', 'IV', 1, docPrefixDefault, date)

  export const docNoChangeFull = (docName, docId, docPrefix, changeValue, docFormat, date) => { 
    const { 
      yearDigits, 
      monthDigits, 
      numberDigits,

      dilimiter,

      machineValue, machineDigits, 
      employeeValue, employeeDigits,
    } = docFormat

    const _docNo_ = db.collection("_docNo_").doc(docName)
    // const document = db.collection(docName).doc(docId)
    // db.firestore.FieldValue.increment(-1)
    return db.runTransaction((trx) => {
      return trx.get(_docNo_).then((documentName) => {
        const yearNumber = (date || new Date()).getFullYear()
        const monthNumber = (date || new Date()).getMonth()+1
        let nextMonthValue = null
        let nextYearValue = null
        let nextValue = null
        if(documentName.exists){
          const docData = documentName.data()
          if(yearDigits && monthDigits){
            console.log("month in ", (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0))
            nextMonthValue = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0) + changeValue
          }
          else
            nextMonthValue = changeValue
          if(yearDigits)
            nextYearValue = (docData[`number${yearNumber}`] || 0) + changeValue
          else
            nextYearValue = changeValue
          nextValue = (docData.number || 0) + changeValue
        }
        else{
          nextMonthValue = 1
          nextYearValue = 1
          nextValue = 1
        }

        let nextDocNo = ""
        if(docPrefix)
          nextDocNo += docPrefix
        if(yearDigits)
          nextDocNo += padLeadingZeros(yearNumber, yearDigits)
        if(monthDigits)
          nextDocNo += padLeadingZeros(monthNumber, monthDigits)
        if(numberDigits){
          if(yearDigits && monthDigits)
            nextDocNo += padLeadingZeros(nextMonthValue, numberDigits)
          else if (yearDigits)
            nextDocNo += padLeadingZeros(nextYearValue, numberDigits)
          else
            nextDocNo += padLeadingZeros(nextValue, numberDigits)
        }

        if(machineValue && machineDigits)
          nextDocNo += " "+padLeadingZeros(machineValue, machineDigits)
        if(employeeValue && employeeDigits)
          nextDocNo += " "+padLeadingZeros(employeeValue, employeeDigits)
          
        console.log("docName = ", docName)
        console.log("id = ", docId)
        console.log("nextDocNo = ", nextDocNo)
        if(Array.isArray(docId)){
          docId.forEach(key => {
            trx.set(db.collection(docName).doc(key), {
              // createDate: fb.firestore.FieldValue.serverTimestamp(),
              updateDate: fb.firestore.FieldValue.serverTimestamp(),
              number: nextValue,
              docNo: nextDocNo,
            });
          })
        }else{
          trx.set(db.collection(docName).doc(docId), {
            // createDate: fb.firestore.FieldValue.serverTimestamp(),
            updateDate: fb.firestore.FieldValue.serverTimestamp(),
            number: nextValue,
            docNo: nextDocNo,
          });
        }
        trx.set(_docNo_, { 
          [`number${yearNumber}${padLeadingZeros(monthNumber,2)}`]: nextMonthValue,
          [`number${yearNumber}`]: nextYearValue,
          number: nextValue,
          docNo: nextDocNo,
        }, { merge: true});
      // }
    });
  })
  .then(() => {
    // getDocNo('order')
    console.log("Transaction successfully committed!")
  })
  .catch((error) => console.log("Transaction failed: ", error));
}
  export const padLeadingZeros = (num, size) => {
    var str = num+"";
    while (str.length < size) str = "0" + str;
    return str;
  }


  // docNoChangeValue(
  //   {
  //     tableName: 'invoice',
  //     docPrefix: 'IV',
  //     keyName: 'docNo',
  //     date: new Date(),
  //     valueChange: 1,
  //   },
  //   {
  //     tableName: 'trainingMember',
  //     id: doc.trainingMemberList,
  //     valueOther: {approveStatus_id: '1', approveStatus: 'Approve'}
  //   }
  // )
  export const docNoChangeValue = (docNo, docUpdate) => {
    docNo.tableName = docNo.tableName // : 'invoice',
    docNo.date = docNo.date || new Date() // : new Date(),
    docNo.valueChange = docNo.valueChange // : 1,

    docUpdate.tableName = docUpdate.tableName // trainingMember = 
    docUpdate.id = docUpdate.id // : doc.trainingMemberList.map(p => p.id),
    docUpdate.valueChange = docUpdate.valueChange // : 1,
    docUpdate.valueOther = docUpdate.valueOther // : {approveStatus_id: '1', approveStatus: 'Approve'}

    const keyName = docNo.keyName || 'docNo'
    const docPrefix = docNo.docPrefix

    const changeValue = docNo.valueChange

    const { 
      yearDigits, 
      monthDigits, 
      dayDigits, 
      numberDigits,

      dilimiter,

      machineValue, machineDigits, 
      employeeValue, employeeDigits,
    } = docPrefixDefault

    const idList = Array.isArray(docUpdate.id) ? docUpdate.id : [docUpdate.id]

    const _docNo_ = db.collection("_docNo_").doc(docNo.tableName)
    // const document = db.collection(docName).doc(docId)
    // db.firestore.FieldValue.increment(-1)
    return db.runTransaction((trx) => {
      return trx.get(_docNo_).then((documentName) => {
        const yearNumber = docNo.date.getFullYear()
        const monthNumber = docNo.date.getMonth()+1
        const dayNumber = docNo.date.getDate()
        // console.log('dayNumber = ', dayNumber)
        let nextMonthValue = 0
        let nextYearValue = 0
        let nextDayValue = 0
        let nextValue = 0
        let nextDocNo = ''

        for (let index = 0; index < idList.length; index+= 1) {
          // console.log('id = ', idList[index])
          const changeValueSum = changeValue * (index+1)
          if(!documentName.exists){
            nextDayValue = changeValueSum
            nextMonthValue = changeValueSum
            nextYearValue = changeValueSum
            nextValue = changeValueSum
          }else{
            const docData = documentName.data()
            if(yearDigits && monthDigits && dayDigits){
              // console.log("day in ", (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0))
              nextDayValue = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}${padLeadingZeros(dayNumber,2)}`] || 0) + changeValueSum
            } 
            else{
              nextDayValue = changeValueSum
            }
            if(yearDigits && monthDigits){
              // console.log("month in ", (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0))
              nextMonthValue = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0) + changeValueSum
            } 
            else
              nextMonthValue = changeValueSum
            if(yearDigits)
              nextYearValue = (docData[`number${yearNumber}`] || 0) + changeValueSum
            else
              nextYearValue = changeValueSum
            nextValue = (docData.number || 0) + changeValueSum
          }
          

          nextDocNo = ""
          if(docPrefix)
            nextDocNo += docPrefix
          if(yearDigits)
            nextDocNo += padLeadingZeros(yearNumber, yearDigits) + dilimiter
          if(monthDigits)
            nextDocNo += padLeadingZeros(monthNumber, monthDigits) + dilimiter
          if(dayDigits)
            nextDocNo += padLeadingZeros(dayNumber, dayDigits) + dilimiter
          if(numberDigits){
            if(yearDigits && monthDigits && dayDigits)
              nextDocNo += padLeadingZeros(nextDayValue, numberDigits)
            else if(yearDigits && monthDigits)
              nextDocNo += padLeadingZeros(nextMonthValue, numberDigits)
            else if (yearDigits)
              nextDocNo += padLeadingZeros(nextYearValue, numberDigits)
            else
              nextDocNo += padLeadingZeros(nextValue, numberDigits)
          }

          if(machineValue && machineDigits)
            nextDocNo += " "+padLeadingZeros(machineValue, machineDigits)
          if(employeeValue && employeeDigits)
            nextDocNo += " "+padLeadingZeros(employeeValue, employeeDigits)

          // console.log('nextDayValue = ', nextDayValue)
          // console.log('nextMonthValue = ', nextMonthValue)
          // console.log('nextYearValue = ', nextYearValue)
          // console.log('nextValue = ', nextValue)
          // console.log('nextDocNo = ', nextDocNo)
          console.log(`DocNo ${nextDocNo} / D ${nextDayValue} / M ${nextMonthValue} / Y ${nextYearValue} / A ${nextValue}`)
            
          // console.log("docName = ", docName)
          // console.log("docUpdate.id = ", docUpdate.id)
          // console.log("docUpdate.id = ", Array.isArray(docUpdate.id))
          // console.log("nextDocNo = ", nextDocNo)
          trx.set(db.collection(docUpdate.tableName).doc(idList[index]), {
            // createDate: fb.firestore.FieldValue.serverTimestamp(),
            updateDate: fb.firestore.FieldValue.serverTimestamp(),
            // docNumber: nextValue,
            docNumber: nextDayValue,
            [keyName]: nextDocNo,
            ...docUpdate.valueOther,
          }, { merge: true})

        }

        trx.set(_docNo_, { 
          [`number${yearNumber}${padLeadingZeros(monthNumber,2)}${padLeadingZeros(dayNumber,2)}`]: nextDayValue,
          [`number${yearNumber}${padLeadingZeros(monthNumber,2)}`]: nextMonthValue,
          [`number${yearNumber}`]: nextYearValue,
          number: nextValue, // nextValue,
          [keyName]: nextDocNo,
        }, { merge: true});
      // }
      });
    })
    .then(() => {
      // getDocNo('order')
      console.log(`table ${docUpdate.tableName} / docNo ${docNo.tableName} Transaction successfully!`)
    })
    .catch((error) => console.log("Transaction failed: ", error));
  }

  export const checkDataExists = (data) => {
    return (
    data === undefined ||
    data === 'undefined' ||
    data === null ||
    data === 'null' || 
    data === ''
    ) ? false : true
  }

  export const docNoChangeValueWithPND = (docNo, docUpdate) => {
    docNo.tableName = docNo.tableName // : 'invoice',
    docNo.date = docNo.date || new Date() // : new Date(),
    docNo.valueChange = docNo.valueChange // : 1,

    docUpdate.tableName = docUpdate.tableName // trainingMember = 
    docUpdate.id = docUpdate.id // : doc.trainingMemberList.map(p => p.id),
    docUpdate.valueChange = docUpdate.valueChange // : 1,
    docUpdate.valueOther = docUpdate.valueOther // : {approveStatus_id: '1', approveStatus: 'Approve'}

    const keyName = docNo.keyName || 'docNo'
    const docPrefix = docNo.docPrefix

    const changeValue = docNo.valueChange

    const { 
      yearDigits, 
      monthDigits, 
      dayDigits, 
      numberDigits,

      dilimiter,

      machineValue, machineDigits, 
      employeeValue, employeeDigits,
    } = docPrefixDefault

    // const idList = Array.isArray(docUpdate.id) ? docUpdate.id : [docUpdate.id]
    const idList = Array.isArray(docUpdate.id) ? docUpdate.id : [{id: docUpdate.id}]

    const _docNo_ = db.collection("_docNo_").doc(docNo.tableName)
    // const document = db.collection(docName).doc(docId)
    // db.firestore.FieldValue.increment(-1)
    return db.runTransaction((trx) => {
      return trx.get(_docNo_).then((documentName) => {
        const yearNumber = docNo.date.getFullYear()
        const monthNumber = docNo.date.getMonth()+1
        const dayNumber = docNo.date.getDate()
        let nextDocNo = ''

        // const whtListId = ['0','1','2','3','4','5','6','7']
        const whtListId = ['0','4','7']
        const lastWhtList = []
        let lastYear = 0
        let lastMonth = 0
        let lastDay = 0
        let lastAll = 0
        whtListId.forEach(key =>{
          lastWhtList[key] = 0
        })

        const addWhtList = []
        let addYear = 0
        let addMonth = 0
        let addDay = 0
        let addAll = 0
        whtListId.forEach(key =>{
          addWhtList[key] = 0
        })

        if(!documentName.exists){
          console.log('!documentName.exists')
        }else{
          const docData = documentName.data()
          if(yearDigits && monthDigits && dayDigits){
            lastDay = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}${padLeadingZeros(dayNumber,2)}`] || 0)
          } 
          if(yearDigits && monthDigits){
            lastMonth = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0) 
            whtListId.forEach(key => {
              lastWhtList[key] = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht${key}`] || 0)
            })
          } 
          if(yearDigits)
            lastYear = (docData[`number${yearNumber}`] || 0)
          lastAll = (docData.number || 0)
        }
        console.log('__________________________ Start Doc __________________________')
        console.log(`LAST D=${lastDay}, M=${lastMonth}, Y=${lastYear}, A=${lastAll} | wht0=${lastWhtList['0']}, wht4=${lastWhtList['4']}, wht7=${lastWhtList['7']}`)

        for (let index = 0; index < idList.length; index+= 1) {
          addDay += 1
          addMonth += 1
          addYear += 1
          addAll += 1
          // const whtType_id = 
          //   idList[index].whtType_id && 
          //   idList[index].whtType_id !== undefined && 
          //   idList[index].whtType_id !== null && 
          //   idList[index].whtType_id !== '' && 
          //   idList[index].whtType_id !== 'null' 
          //     ? idList[index].whtType_id : '0'
          const whtType_id = checkDataExists(idList[index].whtType_id) ? idList[index].whtType_id : '0'
          // console.log(`check co`,idList[index].whtType_id)
          // console.log(`check to`,idList[index])
          console.log(`${idList[index].id} check whtType_id = ${idList[index].whtType_id} => ${whtType_id}`)
          addWhtList[whtType_id] = (addWhtList[whtType_id] || 0) + 1
          console.log(`____________________________________________________________`)
          // console.log(`id = ${idList[index].id}, whtType_id=${whtType_id} / tempWhtList[${whtType_id}]=${tempWhtList[whtType_id]} / nextMonthWhtList[${whtType_id}]=${nextMonthWhtList[whtType_id]}`)
          
          nextDocNo = ""
          if(docPrefix)
            nextDocNo += docPrefix
          if(yearDigits)
            nextDocNo += padLeadingZeros(yearNumber, yearDigits) + dilimiter
          if(monthDigits)
            nextDocNo += padLeadingZeros(monthNumber, monthDigits) + dilimiter
          if(dayDigits)
            nextDocNo += padLeadingZeros(dayNumber, dayDigits) + dilimiter
          if(numberDigits){
            // nextDocNo += '_'
            if(yearDigits && monthDigits && dayDigits)
              nextDocNo += padLeadingZeros(lastDay + addDay, numberDigits)
            else if(yearDigits && monthDigits)
              nextDocNo += padLeadingZeros(lastMonth + addMonth, numberDigits)
            else if (yearDigits)
              nextDocNo += padLeadingZeros(lastYear + addYear, numberDigits)
            else
              nextDocNo += padLeadingZeros(lastAll + addAll, numberDigits)
          }
          if(machineValue && machineDigits)
            nextDocNo += " "+padLeadingZeros(machineValue, machineDigits)
          if(employeeValue && employeeDigits)
            nextDocNo += " "+padLeadingZeros(employeeValue, employeeDigits)

          console.log(`add  D=${addDay}, M=${addMonth}, Y=${addYear}, A=${addAll}, | wht0=${addWhtList['0']}, wht4=${addWhtList['4']}, wht7=${addWhtList['7']}, `)
          console.log(`sum  D=${lastDay + addDay}, M=${lastMonth + addMonth}, Y=${lastYear + addYear}, A=${lastAll + addAll}, | wht0=${lastWhtList['0'] + addWhtList['0']}, wht4=${lastWhtList['4'] + addWhtList['4']}, wht7=${lastWhtList['7'] + addWhtList['7']}, `)
          console.log(`DocNo ${nextDocNo}`)

          // console.log("docName = ", docName)
          // console.log("docUpdate.id = ", docUpdate.id)
          // console.log("docUpdate.id = ", Array.isArray(docUpdate.id))
          // console.log("nextDocNo = ", nextDocNo)
          const whtNumber = (lastWhtList[whtType_id] + addWhtList[whtType_id]) || null
          // console.log("whtNumber = ", whtNumber)
          // console.log("lastWhtList[whtType_id] = ", lastWhtList[whtType_id])
          // console.log("addWhtList[whtType_id] = ", addWhtList[whtType_id])

          // update trainingMember with id (update docNumber, whtNumber)
          console.log("idList = ", idList)
          console.log("idList[index].id = ", idList[index].id)
          trx.set(db.collection(docUpdate.tableName).doc(idList[index].id), {
            // createDate: fb.firestore.FieldValue.serverTimestamp(),
            updateDate: fb.firestore.FieldValue.serverTimestamp(),
            docNumber: lastDay + addDay,
            whtNumber: whtNumber,
            [`pnd`]: whtType_id,
            [`wht`]: whtType_id,
            [`wht${whtType_id}`]: lastWhtList[whtType_id] + addWhtList[whtType_id],
            [keyName]: nextDocNo,
            ...docUpdate.valueOther,
          }, { merge: true})

        }

        // update _docNo_
        let whtNumberforDocNo = {}
        whtListId.forEach(key => {
          whtNumberforDocNo = {
            ...whtNumberforDocNo, 
            [`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht${key}`]: lastWhtList[`${key}`] + addWhtList[`${key}`]
          }
        })
        console.log('whtNumberforDocNo = ', whtNumberforDocNo)
        trx.set(_docNo_, { 
          [`number${yearNumber}${padLeadingZeros(monthNumber,2)}${padLeadingZeros(dayNumber,2)}`]: lastDay + addDay,
          [`number${yearNumber}${padLeadingZeros(monthNumber,2)}`]: lastMonth + addMonth,
          ...whtNumberforDocNo,
          [`number${yearNumber}`]: lastYear + addYear,
          [`number`]: lastAll + addAll, 
          [keyName]: nextDocNo,
        }, { merge: true});
      });
    })
    .then(() => {
      console.log(`table ${docUpdate.tableName} / docNo ${docNo.tableName} Transaction successfully!`)
    })
    .catch((error) => console.log(`table ${docUpdate.tableName} / docNo ${docNo.tableName} Transaction failed: `, error));

  }

  // export const docNoChangeValueWithPND_old = (docNo, docUpdate) => {
  //   docNo.tableName = docNo.tableName // : 'invoice',
  //   docNo.date = docNo.date || new Date() // : new Date(),
  //   docNo.valueChange = docNo.valueChange // : 1,

  //   docUpdate.tableName = docUpdate.tableName // trainingMember = 
  //   docUpdate.id = docUpdate.id // : doc.trainingMemberList.map(p => p.id),
  //   docUpdate.valueChange = docUpdate.valueChange // : 1,
  //   docUpdate.valueOther = docUpdate.valueOther // : {approveStatus_id: '1', approveStatus: 'Approve'}

  //   const keyName = docNo.keyName || 'docNo'
  //   const docPrefix = docNo.docPrefix

  //   const changeValue = docNo.valueChange

  //   const { 
  //     yearDigits, 
  //     monthDigits, 
  //     dayDigits, 
  //     numberDigits,

  //     dilimiter,

  //     machineValue, machineDigits, 
  //     employeeValue, employeeDigits,
  //   } = docPrefixDefault

  //   const idList = Array.isArray(docUpdate.id) ? docUpdate.id : [docUpdate.id]

  //   const _docNo_ = db.collection("_docNo_").doc(docNo.tableName)
  //   // const document = db.collection(docName).doc(docId)
  //   // db.firestore.FieldValue.increment(-1)
  //   return db.runTransaction((trx) => {
  //     return trx.get(_docNo_).then((documentName) => {
  //       const yearNumber = docNo.date.getFullYear()
  //       const monthNumber = docNo.date.getMonth()+1
  //       const dayNumber = docNo.date.getDate()
  //       // console.log('dayNumber = ', dayNumber)
  //       let nextYearValue = 0
  //       let nextMonthValue = 0
  //       // let nextMonthWht0 = 0
  //       // let nextMonthWht4 = 0
  //       // let nextMonthWht7 = 0
  //       let nextDayValue = 0
  //       let nextValue = 0
  //       let nextDocNo = ''

  //       // let tempWht0 = 0
  //       // let tempWht4 = 0
  //       // let tempWht7 = 0

  //       // const whtListId = ['0','1','2','3','4','5','6','7']
  //       const whtListId = ['0','4','7']
  //       const tempWhtList = []
  //       const nextMonthWhtList = []
  //       whtListId.forEach(key =>{
  //         tempWhtList[key] = 0
  //         nextMonthWhtList[key] = 0
  //       })

  //       for (let index = 0; index < idList.length; index+= 1) {
  //         const whtType_id = idList[index].whtType_id || '0'
  //         console.log(`____________________________________________________________`)
  //         // console.log(`id = ${idList[index].id}, whtType_id=${whtType_id} / tempWhtList[${whtType_id}]=${tempWhtList[whtType_id]} / nextMonthWhtList[${whtType_id}]=${nextMonthWhtList[whtType_id]}`)
  //         const changeValueSum = changeValue * (index+1)
  //         if(!documentName.exists){
  //           console.log('!documentName.exists')
  //           nextDayValue = changeValueSum
  //           nextMonthValue = changeValueSum
  //           nextYearValue = changeValueSum
  //           nextValue = changeValueSum

  //           whtListId.forEach(key => {
  //             nextMonthWhtList[key] = changeValueSum
  //           })
  //           if(whtType_id === ''  || whtType_id === undefined){
  //             tempWhtList['0'] = changeValue
  //           }else{
  //             tempWhtList[whtType_id] = changeValue
  //             nextMonthWhtList[whtType_id] = changeValue
  //           }
  //         }else{
  //           const docData = documentName.data()
  //           if(yearDigits && monthDigits && dayDigits){
  //             // console.log("day in ", (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0))
  //             nextDayValue = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}${padLeadingZeros(dayNumber,2)}`] || 0) + changeValueSum
  //           } 
  //           else{
  //             nextDayValue = changeValueSum
  //           }
  //           if(yearDigits && monthDigits){
  //             // console.log("month in ", (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0))
  //             nextMonthValue = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0) + changeValueSum
  //             if(whtType_id === ''  || whtType_id === undefined){
  //               tempWhtList['0'] = changeValue
  //               nextMonthWhtList['0'] = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht${'0'}`] || 0) + changeValue
  //             }else{
  //               tempWhtList[whtType_id] = changeValue
  //               nextMonthWhtList[whtType_id] = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht${whtType_id}`] || 0) + changeValue
  //             }

  //           } 
  //           else
  //             nextMonthValue = changeValueSum
  //           if(yearDigits)
  //             nextYearValue = (docData[`number${yearNumber}`] || 0) + changeValueSum
  //           else
  //             nextYearValue = changeValueSum
  //           nextValue = (docData.number || 0) + changeValueSum
  //         }
  //         console.log(`temp wht0=${tempWhtList['0']}, wht4=${tempWhtList['4']}, wht7=${tempWhtList['7']} | next wht0=${nextMonthWhtList['0']}, wht4=${nextMonthWhtList['4']}, wht7=${nextMonthWhtList['7']}`)
  //         console.log(`whtType_id(${whtType_id}) / temp=${tempWhtList[whtType_id]} / next=${nextMonthWhtList[whtType_id]}`)
          
  //         nextDocNo = ""
  //         if(docPrefix)
  //           nextDocNo += docPrefix
  //         if(yearDigits)
  //           nextDocNo += padLeadingZeros(yearNumber, yearDigits) + dilimiter
  //         if(monthDigits)
  //           nextDocNo += padLeadingZeros(monthNumber, monthDigits) + dilimiter
  //         if(dayDigits)
  //           nextDocNo += padLeadingZeros(dayNumber, dayDigits) + dilimiter
  //         if(numberDigits){
  //           if(yearDigits && monthDigits && dayDigits)
  //             nextDocNo += padLeadingZeros(nextDayValue, numberDigits)
  //           else if(yearDigits && monthDigits)
  //             nextDocNo += padLeadingZeros(nextMonthValue, numberDigits)
  //           else if (yearDigits)
  //             nextDocNo += padLeadingZeros(nextYearValue, numberDigits)
  //           else
  //             nextDocNo += padLeadingZeros(nextValue, numberDigits)
  //         }

  //         if(machineValue && machineDigits)
  //           nextDocNo += " "+padLeadingZeros(machineValue, machineDigits)
  //         if(employeeValue && employeeDigits)
  //           nextDocNo += " "+padLeadingZeros(employeeValue, employeeDigits)

  //         // console.log('nextDayValue = ', nextDayValue)
  //         // console.log('nextMonthValue = ', nextMonthValue)
  //         // console.log('nextYearValue = ', nextYearValue)
  //         // console.log('nextValue = ', nextValue)
  //         // console.log('nextDocNo = ', nextDocNo)

  //         console.log(`DocNo ${nextDocNo} ,D=${nextDayValue} ,M=${nextMonthValue} ,Y=${nextYearValue} ,A=${nextValue} / wht0=${nextMonthWhtList['0']} / wht4=${nextMonthWhtList['4']}/ wht7=${nextMonthWhtList['7']} / current wht${whtType_id || 0} ${idList[index].whtType}`)
            
  //         // console.log("docName = ", docName)
  //         // console.log("docUpdate.id = ", docUpdate.id)
  //         // console.log("docUpdate.id = ", Array.isArray(docUpdate.id))
  //         // console.log("nextDocNo = ", nextDocNo)
  //         let whtNumber = 0
  //         if(whtType_id === ''  || whtType_id === undefined){
  //           whtNumber = nextMonthWhtList['0']
  //         }else{
  //           whtNumber = nextMonthWhtList[whtType_id]
  //         }

  //         // update trainingMember with id (update docNumber, whtNumber)
  //         trx.set(db.collection(docUpdate.tableName).doc(idList[index].id), {
  //           // createDate: fb.firestore.FieldValue.serverTimestamp(),
  //           updateDate: fb.firestore.FieldValue.serverTimestamp(),
  //           // docNumber: nextValue,
  //           docNumber: nextDayValue,
  //           whtNumber: whtNumber,
  //           [`pnd`]: whtType_id,
  //           // [`wht0`]: nextMonthWht0,
  //           // [`wht4`]: nextMonthWht4,
  //           // [`wht7`]: nextMonthWht7,
  //           [`wht${whtType_id || '0'}`]: nextMonthWhtList[`${whtType_id || '0'}`],
  //           [keyName]: nextDocNo,
  //           ...docUpdate.valueOther,
  //         }, { merge: true})

  //       }

  //       // update _docNo_
  //       let whtNumberforDocNo = {}
  //       whtListId.forEach(key => {
  //         whtNumberforDocNo = {...whtNumberforDocNo, [`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht${key}`]: nextMonthWhtList[`${key}`]}
  //       })
  //       console.log('whtNumberforDocNo = ', whtNumberforDocNo)
  //       trx.set(_docNo_, { 
  //         [`number${yearNumber}${padLeadingZeros(monthNumber,2)}${padLeadingZeros(dayNumber,2)}`]: nextDayValue,
  //         [`number${yearNumber}${padLeadingZeros(monthNumber,2)}`]: nextMonthValue,
  //         // [`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht0`]: nextMonthWht0,
  //         // [`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht4`]: nextMonthWht4,
  //         // [`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht7`]: nextMonthWht7,
  //         // [`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht${whtType_id || '0'}`]: nextMonthWhtList[`${whtType_id || '0'}`],
  //         ...whtNumberforDocNo,
  //         [`number${yearNumber}`]: nextYearValue,
  //         number: nextValue, // nextValue,
  //         [keyName]: nextDocNo,
  //       }, { merge: true});
  //     // }
  //     });
  //   })
  //   .then(() => {
  //     // getDocNo('order')
  //     console.log(`table ${docUpdate.tableName} / docNo ${docNo.tableName} Transaction successfully!`)
  //   })
  //   .catch((error) => console.log("Transaction failed: ", error));

  // }

  // export const docNoChangeValueWithPND_backup = (docNo, docUpdate) => {
  //   docNo.tableName = docNo.tableName // : 'invoice',
  //   docNo.date = docNo.date || new Date() // : new Date(),
  //   docNo.valueChange = docNo.valueChange // : 1,

  //   docUpdate.tableName = docUpdate.tableName // trainingMember = 
  //   docUpdate.id = docUpdate.id // : doc.trainingMemberList.map(p => p.id),
  //   docUpdate.valueChange = docUpdate.valueChange // : 1,
  //   docUpdate.valueOther = docUpdate.valueOther // : {approveStatus_id: '1', approveStatus: 'Approve'}

  //   const keyName = docNo.keyName || 'docNo'
  //   const docPrefix = docNo.docPrefix

  //   const changeValue = docNo.valueChange

  //   const { 
  //     yearDigits, 
  //     monthDigits, 
  //     dayDigits, 
  //     numberDigits,

  //     dilimiter,

  //     machineValue, machineDigits, 
  //     employeeValue, employeeDigits,
  //   } = docPrefixDefault

  //   const idList = Array.isArray(docUpdate.id) ? docUpdate.id : [docUpdate.id]

  //   const _docNo_ = db.collection("_docNo_").doc(docNo.tableName)
  //   // const document = db.collection(docName).doc(docId)
  //   // db.firestore.FieldValue.increment(-1)
  //   return db.runTransaction((trx) => {
  //     return trx.get(_docNo_).then((documentName) => {
  //       const yearNumber = docNo.date.getFullYear()
  //       const monthNumber = docNo.date.getMonth()+1
  //       const dayNumber = docNo.date.getDate()
  //       // console.log('dayNumber = ', dayNumber)
  //       let nextYearValue = 0
  //       let nextMonthValue = 0
  //       let nextMonthWht0 = 0
  //       let nextMonthWht4 = 0
  //       let nextMonthWht7 = 0
  //       let nextDayValue = 0
  //       let nextValue = 0
  //       let nextDocNo = ''

  //       let tempWht0 = 0
  //       let tempWht4 = 0
  //       let tempWht7 = 0

  //       for (let index = 0; index < idList.length; index+= 1) {
  //         const { whtType_id } = idList[index]
  //         // console.log('id = ', idList[index])
  //         const changeValueSum = changeValue * (index+1)
  //         if(!documentName.exists){
  //           nextDayValue = changeValueSum
  //           nextMonthValue = changeValueSum
  //           nextMonthWht0 = changeValueSum
  //           nextMonthWht4 = changeValueSum
  //           nextMonthWht7 = changeValueSum
  //           if(whtType_id === '0' || whtType_id === ''  || whtType_id === undefined){
  //             tempWht0 += changeValue
  //             nextMonthWht0 = tempWht0
  //           }else if(whtType_id === '4'){
  //             tempWht4 += changeValue
  //             nextMonthWht4 = tempWht4
  //           }else if(whtType_id === '7'){
  //             tempWht7 += changeValue
  //             nextMonthWht7 = tempWht7
  //           }
  //           nextYearValue = changeValueSum
  //           nextValue = changeValueSum

  //         }else{
  //           const docData = documentName.data()
  //           if(yearDigits && monthDigits && dayDigits){
  //             // console.log("day in ", (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0))
  //             nextDayValue = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}${padLeadingZeros(dayNumber,2)}`] || 0) + changeValueSum
  //           } 
  //           else{
  //             nextDayValue = changeValueSum
  //           }
  //           if(yearDigits && monthDigits){
  //             // console.log("month in ", (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0))
  //             nextMonthValue = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}`] || 0) + changeValueSum
  //             if(whtType_id === '0' || whtType_id === '' || whtType_id === undefined){
  //               tempWht0 += changeValue
  //               nextMonthWht0 = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht0`] || 0) + tempWht0
  //             }else  if(whtType_id === '4'){
  //               tempWht4 += changeValue
  //               nextMonthWht4 = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht4`] || 0) + tempWht4
  //             }else if(whtType_id === '7'){
  //               tempWht7 += changeValue
  //               nextMonthWht7 = (docData[`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht7`] || 0) + tempWht7
  //             }
  //           } 
  //           else
  //             nextMonthValue = changeValueSum
  //           if(yearDigits)
  //             nextYearValue = (docData[`number${yearNumber}`] || 0) + changeValueSum
  //           else
  //             nextYearValue = changeValueSum
  //           nextValue = (docData.number || 0) + changeValueSum
  //         }
          
  //         nextDocNo = ""
  //         if(docPrefix)
  //           nextDocNo += docPrefix
  //         if(yearDigits)
  //           nextDocNo += padLeadingZeros(yearNumber, yearDigits) + dilimiter
  //         if(monthDigits)
  //           nextDocNo += padLeadingZeros(monthNumber, monthDigits) + dilimiter
  //         if(dayDigits)
  //           nextDocNo += padLeadingZeros(dayNumber, dayDigits) + dilimiter
  //         if(numberDigits){
  //           if(yearDigits && monthDigits && dayDigits)
  //             nextDocNo += padLeadingZeros(nextDayValue, numberDigits)
  //           else if(yearDigits && monthDigits)
  //             nextDocNo += padLeadingZeros(nextMonthValue, numberDigits)
  //           else if (yearDigits)
  //             nextDocNo += padLeadingZeros(nextYearValue, numberDigits)
  //           else
  //             nextDocNo += padLeadingZeros(nextValue, numberDigits)
  //         }

  //         if(machineValue && machineDigits)
  //           nextDocNo += " "+padLeadingZeros(machineValue, machineDigits)
  //         if(employeeValue && employeeDigits)
  //           nextDocNo += " "+padLeadingZeros(employeeValue, employeeDigits)

  //         // console.log('nextDayValue = ', nextDayValue)
  //         // console.log('nextMonthValue = ', nextMonthValue)
  //         // console.log('nextYearValue = ', nextYearValue)
  //         // console.log('nextValue = ', nextValue)
  //         // console.log('nextDocNo = ', nextDocNo)

  //         console.log(`DocNo ${nextDocNo} / D ${nextDayValue} / M ${nextMonthValue} / Y ${nextYearValue} / A ${nextValue} / wht0 ${nextMonthWht0} / wht4 ${nextMonthWht4}/ wht7 ${nextMonthWht7} / current wht${whtType_id || 0} ${idList[index].whtType}`)
            
  //         // console.log("docName = ", docName)
  //         // console.log("docUpdate.id = ", docUpdate.id)
  //         // console.log("docUpdate.id = ", Array.isArray(docUpdate.id))
  //         // console.log("nextDocNo = ", nextDocNo)
  //         let whtNumber = 0
  //         if(whtType_id === '0'  || whtType_id === '' || whtType_id === undefined)
  //           whtNumber = nextMonthWht0
  //         else  if(whtType_id === '4')
  //           whtNumber = nextMonthWht4
  //         else if(whtType_id === '7')
  //           whtNumber = nextMonthWht7
  //         trx.set(db.collection(docUpdate.tableName).doc(idList[index].id), {
  //           // createDate: fb.firestore.FieldValue.serverTimestamp(),
  //           updateDate: fb.firestore.FieldValue.serverTimestamp(),
  //           // docNumber: nextValue,
  //           docNumber: nextDayValue,
  //           whtNumber: whtNumber,
  //           [`pnd`]: whtType_id,
  //           [`wht0`]: nextMonthWht0,
  //           [`wht4`]: nextMonthWht4,
  //           [`wht7`]: nextMonthWht7,
  //           [keyName]: nextDocNo,
  //           ...docUpdate.valueOther,
  //         }, { merge: true})

  //       }

  //       trx.set(_docNo_, { 
  //         [`number${yearNumber}${padLeadingZeros(monthNumber,2)}${padLeadingZeros(dayNumber,2)}`]: nextDayValue,
  //         [`number${yearNumber}${padLeadingZeros(monthNumber,2)}`]: nextMonthValue,
  //         [`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht0`]: nextMonthWht0,
  //         [`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht4`]: nextMonthWht4,
  //         [`number${yearNumber}${padLeadingZeros(monthNumber,2)}_wht7`]: nextMonthWht7,
  //         [`number${yearNumber}`]: nextYearValue,
  //         number: nextValue, // nextValue,
  //         [keyName]: nextDocNo,
  //       }, { merge: true});
  //     // }
  //     });
  //   })
  //   .then(() => {
  //     // getDocNo('order')
  //     console.log(`table ${docUpdate.tableName} / docNo ${docNo.tableName} Transaction successfully!`)
  //   })
  //   .catch((error) => console.log("Transaction failed: ", error));

  // }


  export const updateUserCredit = (user, userBenefit_id, dataChange, creditChange, pointChange) => {
    console.log('userBenefit_id = ', userBenefit_id)
    const _docNo_ = db.collection("user").doc(userBenefit_id)
    return db.runTransaction((trx) => {
      return trx.get(_docNo_).then((documentName) => {
        if(!documentName.exists){
          console.log('!documentName.exists')
        }else{
          const userBenefitData = documentName.data()
          console.log('userBenefitData = ', userBenefitData)

          const userCreate = user ? {createUser_id: user.id, createUser: `${user.firstName} ${user.lastName}`, createDate: fb.firestore.FieldValue.serverTimestamp()} : {createDate: fb.firestore.FieldValue.serverTimestamp()}
          const userUpdate = user ? {updateUser_id: user.id, updateUser: `${user.firstName} ${user.lastName}`, updateDate: fb.firestore.FieldValue.serverTimestamp()} : {updateDate: fb.firestore.FieldValue.serverTimestamp()}
          
          const creditBalance = (userBenefitData.creditBalance || 0) + (creditChange || 0)
          const pointBalance  = (userBenefitData.pointBalance || 0)  + (pointChange || 0)

          const userData = {
            creditBalance: creditBalance,
            pointBalance: pointBalance,
          }
          
          const userBenefit = {userBenefit_id: userBenefit_id, userBenefit: `${userBenefitData.firstName} ${userBenefitData.lastName}`} 
          const userTransactionData = {
            creditChange: creditChange || 0,
            pointChange: pointChange || 0,
            ...dataChange,
            ...userBenefit,
          }
          console.log('userData = ', userData)
          console.log('userTransactionData = ', userTransactionData)

          trx.set(db.collection('user').doc(userBenefit_id), {
            ...userUpdate, 
            ...userData, 
            // creditBalance: fb.firestore.FieldValue.increment(creditChange),
            // pointBalance: fb.firestore.FieldValue.increment(pointChange),
          }, { merge: true})

          trx.set(db.collection('userTransaction').doc(), { 
            ...userCreate,
            ...userUpdate, 
            ...userData,
            ...userTransactionData,
          })

        }
      })
    })
    .then(() => {
      console.log(`Transaction successfully!`)
    })
    .catch((error) => console.log(`Transaction failed: `, error));
  }

            // const runDocNo = () => { 
            //   const _docNo_ = db.collection("_docNo_").doc(docName1);
            //   const order = db.collection(docName1).doc(id);
            //   // db.firestore.FieldValue.increment(-1)
            //   return db.runTransaction((trx) => {
            //     return trx.get(_docNo_).then((documentName) => {
            //           const docData = documentName.data()
            //           const nextValue = (documentName.exists ? docData.number : 0) + addValue
            //           const nextDocNo = prefixName + padLeadingZeros(nextValue, prefixZero)
            //           console.log("nextDocNo = ", nextDocNo)
            //           trx.set(order, {
            //             // createDate: fb.firestore.FieldValue.serverTimestamp(),
            //             updateDate: fb.firestore.FieldValue.serverTimestamp(),
            //             number: nextValue,
            //             docNo: nextDocNo,
            //           });
            //           trx.set(_docNo_, { 
            //             number: nextValue,
            //             docNo: nextDocNo,
            //           }, { merge: true});
            //         // }
            //       });
            //     })
            //     .then(() => {
            //       getDocNo()
            //       console.log("Transaction successfully committed!")
            //     })
            //     .catch((error) => console.log("Transaction failed: ", error));
            // }


            // {customHelmet(
            //   company.companyIcon
            //   ,helmet.logo192
            //   ,helmet.logo512
            //   ,manifest
            //   ,helmet.title
            //   ,helmet.keywords
            //   ,helmet.description
            //   ,helmet.author
            //   ,helmet.url)
            // }
export const customHelmet = (favicon,logo192,image,mainifest,title,keywords,description,author,url) =>{
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" href={favicon} />
      <link rel="apple-touch-icon" href={logo192} />
      <link rel="manifest" href={mainifest} />

      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta http-equiv="Content-type" content="text/html; charset=utf-8"/>
      <meta name="theme-color" content="#000000" />

      <meta name="title" content={title} />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
      <meta name="author" content={author} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content={title} />
      <meta property="og:title" content={keywords} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={keywords} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  )
}
export const customManifest = (favicon, logo192, logo512, short_name, name) =>{
  return {
    "short_name": short_name,
    "name": name,
    "icons": [
      {
        "src": favicon,
        "sizes": "64x64 32x32 24x24 16x16",
        "type": "image/x-icon"
      },
      {
        "src": logo192,
        "type": "image/png",
        "sizes": "192x192"
      },
      {
        "src": logo512,
        "type": "image/png",
        "sizes": "512x512"
      }
    ],
    "start_url": ".",
    "display": "standalone",
    "theme_color": "#000000",
    "background_color": "#ffffff"
  }

}

// numberToReadText("1234567.89")
// numberToReadText("1234561.12")
export const numberToReadText = numberOriginal => {
  let number = numberOriginal
  //ตัดสิ่งที่ไม่ต้องการทิ้งลงโถส้วม
  for (let i = 0; i < number.length; i+=1) {
    number = number.replace(",", ""); //ไม่ต้องการเครื่องหมายคอมมาร์
    number = number.replace(" ", ""); //ไม่ต้องการช่องว่าง
    number = number.replace("บาท", ""); //ไม่ต้องการตัวหนังสือ บาท
    number = number.replace("฿", ""); //ไม่ต้องการสัญลักษณ์สกุลเงินบาท
  }
  //สร้างอะเรย์เก็บค่าที่ต้องการใช้เอาไว้
  var TxtNumArr = ["ศูนย์", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า", "สิบ"]
  var TxtDigitArr = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน"]
  // var TxtDigitArr = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน"]
  var BahtText = "";
  //ตรวจสอบดูซะหน่อยว่าใช่ตัวเลขที่ถูกต้องหรือเปล่า ด้วย isNaN == true ถ้าเป็นข้อความ == false ถ้าเป็นตัวเลข
  if (Number.isNaN(number) ) {
    return "ข้อมูลนำเข้าไม่ถูกต้อง";
    //ตรวสอบอีกสักครั้งว่าตัวเลขมากเกินความต้องการหรือเปล่า
  } else if ((number - 0) > 9999999.9999) {
      return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้"
    } else {
      //พรากทศนิยม กับจำนวนเต็มออกจากกัน (บาปหรือเปล่าหนอเรา พรากคู่เขา)
      // console.log('number = ',number)
      number = number.split(".")
      // .split(".");
      //ขั้นตอนต่อไปนี้เป็นการประมวลผลดูกันเอาเองครับ แบบว่าขี้เกียจจะจิ้มดีดแล้ว อิอิอิ
      if (number[1].length > 0) {
        number[1] = number[1].substring(0, 2);
      }
      var numberLen = number[0].length - 0;
      for (let i = 0; i < numberLen; i+=1) {
        const tmp = number[0].substring(i, i + 1) - 0;
        if (tmp !== 0) {
          if ((i === (numberLen - 1)) && (tmp === 1)) {
            BahtText += "เอ็ด";
          } else
            if ((i === (numberLen - 2)) && (tmp === 2)) {
              BahtText += "ยี่";
            } else
              if ((i === (numberLen - 2)) && (tmp === 1)) {
                BahtText += "";
              } else {
                BahtText += TxtNumArr[tmp];
              }
          BahtText += TxtDigitArr[numberLen - i - 1];
        }
      }
      BahtText += "บาท";
      if ((number[1] === "0") || (number[1] === "00")) {
        BahtText += "ถ้วน";
      } else {
        const DecimalLen = number[1].length - 0;
        for (var i = 0; i < DecimalLen; i+=1) {
          var tmp = number[1].substring(i, i + 1) - 0;
          if (tmp !== 0) {
            if ((i === (DecimalLen - 1)) && (tmp === 1)) {
              BahtText += "เอ็ด";
            } else
              if ((i === (DecimalLen - 2)) && (tmp === 2)) {
                BahtText += "ยี่";
              } else
                if ((i === (DecimalLen - 2)) && (tmp === 1)) {
                  BahtText += "";
                } else {
                  BahtText += TxtNumArr[tmp];
                }
            BahtText += TxtDigitArr[DecimalLen - i - 1];
          }
        }
        BahtText += "สตางค์";
      }
      // console.log('BahtText = ', BahtText)
      return BahtText;
    }
}



// const [downloaded, setDownloaded] = React.useState(0)
// const nextFunction1 = (next, data) => {
//   setDownloaded(next)
//   console.log('nextFunction', data)
// }
// const downloadAll = () => {
//   functionLoopWithDelay(1000, 0, docList.length, docList, nextFunction1)
// }
export const functionLoopWithDelay = (delay, next, limit, dataList, nextFunction) => {
  if(next < limit){
    console.log(`Round ${next+1}/${limit}`)
    nextFunction(next+1, dataList[next])
    setTimeout(() => {  
      functionLoopWithDelay(delay, next+1, limit, dataList, nextFunction)
    }, delay || 2000);
  }
}



// check data for billing #################################

const checkDataNotEmpty = (inCompleteList, data, name) => {
  if(data[name] === undefined || 
    data[name] === '' || 
    data[name] === 'null' || 
    data[name] === null)
    inCompleteList.push(name)
}
const checkDataNotEmptyList = (inCompleteList, data, nameList) => {
  // console.log('nameList =', nameList)
  if(nameList && nameList.length > 0){
    nameList.forEach(name => {
      // console.log('name =', name)
      checkDataNotEmpty(inCompleteList, data, name)
    })
  }
}

export const checkUserDataForBilling = (data) => {
  const inCompleteList = []
  checkDataNotEmptyList(inCompleteList, data, [
    'firstName',
    'lastName',
    'mobile',
    'email',
  ])
  if(!checkDataExists(data.whtType_id) || data.whtType_id === '0'){
    checkDataNotEmptyList(inCompleteList, data, [
      'citizenId',
      'companyAddress',
    ])
  } else if(data.whtType_id.match('4|7')){
    checkDataNotEmptyList(inCompleteList, data, [
      'companyTaxId',
      'companyName',
      'companyBranch',
      'companyAddress',

      'whtType',
    ])
  }
  return inCompleteList
}

const checkUserDataForBNIMember = (data) => {
  const inCompleteList = []
  checkDataNotEmptyList(inCompleteList, data, [
    'firstName',
    'lastName',
    'mobile',
    'email',

    'renewalDate',
    'chapter',
    'chapter_id',
    'role',
    'role_id',
  ])
  return inCompleteList
}
// check data for billing #################################